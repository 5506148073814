import {
    getAllNodes,
    getNodesWithoutInputConnections,
    getEndingNodes,
    getMultiselectNodes,
    getPersonSelectNodes,
    getSelectNodes,
} from './nodes'

/**
 *
 * Each function shoudl export the same object containing various fields or null in case when non validation errors ahve been found
 *
 * - validator => name of validator used (e.g. - Number of starting nodes)
 * - message => explanation string giving user more context (e.g. - Your conversation contains multiple nodes without inputs, but only one is allowed)
 * - steps => string containing simple instructions how such validation error can be fixed (e.g - Either delete extra nodes without inputs or connect them into conversation)
 * - nodes => array of nodes with this validation error or null
 */

export function numberOfStartNodesValidator(editor) {
    const validator = 'Number of START nodes'
    const nodes = getNodesWithoutInputConnections(editor)

    if (nodes.length === 1) return null
    else if (nodes.length === 0)
        return {
            validator,
            message: 'Your conversation does not contain any starting node',
            steps:
                'Please create a node without any connections going into its input. This node will be considered a starting node of your conversation',
            nodes: null,
        }
    else
        return {
            validator,
            message: 'Your conversation contains multiple starting nodes',
            steps:
                'Please, either remove redundant nodes without connection in their inputs or connect them to other nodes. Your conversationn should have only one node without input connections which will be considered a start of the conversation.',
            nodes: nodes,
        }
}

export function numberOfEndNodesValidator(editor) {
    const validator = 'Number of END nodes'
    const nodes = getEndingNodes(editor)

    if (nodes.length === 1) return null
    else if (nodes.length === 0)
        return {
            validator,
            message: 'Your conversation does not contain any ending node',
            steps:
                'Please create an "END" node and connect it to the last node of the conversation. (END node can also contain some text so you should consider swapping the last node for an END node)',
            nodes: null,
        }
    else
        return {
            validator,
            message: 'Your conversation contains multiple ending nodes',
            steps:
                'Please remove redundant ending nodes. Your conversationn should have only one END node (You can connect multiple nodes to END nnode input).',
            nodes: nodes,
        }
}

export function checkMinMaxMultiselectNodesValidator(editor) {
    const validator = 'Min / Max of MULTSELECT nodes'
    const nodes = getMultiselectNodes(editor)
    const resultNodes = []

    nodes.forEach((node) => {
        // MIN or MAX is less then 0 (MIN or MAX shoudl be positive integers)
        if (node.data.min < 0 || node.data.max < 0) {
            resultNodes.push(node)
        }

        // MAX is less then or equal to MIN, (MAX always needs to be more then MIN)
        else if (node.data.max <= node.data.min) {
            resultNodes.push(node)
        }

        // MAX is zero (MAX cannot be zero)
        else if (node.data.max === 0) {
            resultNodes.push(node)
        }
    })

    if (resultNodes.length === 0) return null
    else
        return {
            validator,
            message: 'MIN / MAX values of your MULTISELECT nodes are invalid',
            steps:
                'Please correct the MIN / MAX pairs in all affected MULTISELECT nodes. (MIN / MAX should be positive, MAX should always be higher than MIN, MAX cannot be zero)',
            nodes: resultNodes,
        }
}

export function checkMinMaxPersonSelectNodesValidator(editor) {
    const validator = 'Min / Max of PERSON-SELECT nodes'
    const nodes = getPersonSelectNodes(editor)
    const resultNodes = []

    nodes.forEach((node) => {
        // MIN or MAX is less then 0 (MIN or MAX shoudl be positive integers)
        if (node.data.min < 0 || node.data.max < 0) {
            resultNodes.push(node)
        }

        // MAX is less then or equal to MIN, (MAX always needs to be more then MIN)
        else if (node.data.max <= node.data.min) {
            resultNodes.push(node)
        }

        // MAX is zero (MAX cannot be zero)
        else if (node.data.max === 0) {
            resultNodes.push(node)
        }
    })

    if (resultNodes.length === 0) return null
    else
        return {
            validator,
            message: 'MIN / MAX values of your PERSON-SELECT nodes are invalid',
            steps:
                'Please correct the MIN / MAX pairs in all affected PERSON-SELECT nodes. (MIN / MAX should be positive, MAX should always be higher than MIN, MAX cannot be zero)',
            nodes: resultNodes,
        }
}

export function checkMultiSelectOutConnectionsValidator(editor) {
    const validator = 'Options connections of MULTISELECT node'
    const nodes = getMultiselectNodes(editor)
    const resultNodes = []

    nodes.forEach((node) => {
        if (Array.from(node.outputs.values()).some((value) => value.connections.length === 0)) {
            resultNodes.push(node)
        }
    })

    if (resultNodes.length === 0) return null
    else
        return {
            validator,
            message: 'MULTISELECT nodes does not have all options (answers) connected to other nodes',
            steps: 'Please connect all the options (answers) to some nodes. Or remove the option from the list.',
            nodes: resultNodes,
        }
}

export function checkSelectOutConnectionsValidator(editor) {
    const validator = 'Options connections of SELECT node'
    const nodes = getSelectNodes(editor)
    const resultNodes = []

    nodes.forEach((node) => {
        if (Array.from(node.outputs.values()).some((value) => value.connections.length === 0)) {
            resultNodes.push(node)
        }
    })

    if (resultNodes.length === 0) return null
    else
        return {
            validator,
            message: 'SELECT nodes does not have all options (answers) connected to other nodes',
            steps: 'Please connect all the options (answers) to some nodes. Or remove the option from the list.',
            nodes: resultNodes,
        }
}

export function checkQuestionTextEmptyValidator(editor) {
    const validator = 'Question TEXT is empty'
    const nodes = getAllNodes(editor)
    const resultNodes = []

    if (nodes && nodes.length > 0) {
        editor.nodes.forEach((node) => {
            if (!node.data.questionText) {
                resultNodes.push(node)
            }
        })
    }

    if (resultNodes.length === 0) return null
    else {
        return {
            validator,
            message: 'Your conversation contains nodes with empy texts',
            steps: 'Please fill in the missing content',
            nodes: resultNodes,
        }
    }
}

export function checkMultiSelectMinAndNumberOfOptionsValidator(editor) {
    const validator = 'MIN value and number of options in MULTISELECT'
    const nodes = getMultiselectNodes(editor)
    const resultNodes = []

    nodes.forEach((node) => {
        if (node.data.min > node.data.iterator - 1) {
            resultNodes.push(node)
        }
    })

    if (resultNodes.length === 0) return null
    else
        return {
            validator,
            message: 'MIN value is higher than number of optionns provided',
            steps: 'Please add additional options or lower the MIN value.',
            nodes: resultNodes,
        }
}

export function checkMultiSelectMaxAndNumberOfOptionsValidator(editor) {
    const validator = 'MAX value and number of options in MULTISELECT'
    const nodes = getMultiselectNodes(editor)
    const resultNodes = []

    nodes.forEach((node) => {
        if (node.data.max > node.data.iterator - 1) {
            resultNodes.push(node)
        }
    })

    if (resultNodes.length === 0) return null
    else
        return {
            validator,
            message: 'MAX value is higher than number of optionns provided',
            steps: 'Please add additional options or lower the MAX value.',
            nodes: resultNodes,
        }
}

export function checkSelectOptionsTextEmptyValidator(editor) {
    const validator = 'Empty text options in SELECT'
    const nodes = getSelectNodes(editor)
    const resultNodes = []

    nodes.forEach((node) => {
        const optionKeys = Object.keys(node.data).filter((key) => key.indexOf('opt-') === 0)
        if (optionKeys && optionKeys.length > 0) {
            optionKeys.forEach((key) => {
                if (!node.data[key]) {
                    if (!resultNodes.find((n) => n.data.questionId === node.data.questionId)) {
                        resultNodes.push(node)
                    }
                }
            })
        }
    })

    if (resultNodes.length === 0) return null
    else
        return {
            validator,
            message: 'Some options in SELECT are empty',
            steps: 'Please fill in the missing content of all SELECT options or remove the empty ones.',
            nodes: resultNodes,
        }
}

export function checkMultiselectOptionsTextEmptyValidator(editor) {
    const validator = 'Empty text options in MULTISELECT'
    const nodes = getMultiselectNodes(editor)
    const resultNodes = []

    nodes.forEach((node) => {
        const optionKeys = Object.keys(node.data).filter((key) => key.indexOf('opt-') === 0)
        if (optionKeys && optionKeys.length > 0) {
            optionKeys.forEach((key) => {
                if (!node.data[key]) {
                    if (!resultNodes.find((n) => n.data.questionId === node.data.questionId)) {
                        resultNodes.push(node)
                    }
                }
            })
        }
    })

    if (resultNodes.length === 0) return null
    else
        return {
            validator,
            message: 'Some options in MULTISELECT are empty',
            steps: 'Please fill in the missing content of all MULTISELECT options or remove the empty ones.',
            nodes: resultNodes,
        }
}
