import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

type Props = {
    text: string | null
}

const ErrorDisplay: React.FC<Props> = ({ text }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography variant="h4">Error</Typography>
            <pre>{text}</pre>
        </div>
    )
}

export default ErrorDisplay
