import { createContext } from 'react'

import { Conversation } from '../../models/Conversation'

type ContextProps = {
    conversation: Conversation
    setConversation: React.Dispatch<any>
}

const defaultConversation = {
    id: '',
    name: '',
    topic: '',
    organisation: '',
    language: 'en',
    data: '',
    topicId: '',
    archived: false,
    status: 'open',
}

const defaultContext = {
    conversation: defaultConversation,
    setConversation: () => () => {},
}

export const ConversationContext = createContext<ContextProps>(defaultContext)
