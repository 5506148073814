import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase'
import { makeStyles, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { AuthenticationContext } from '../context/AuthenticationProvider'
import Layout from '../common/Layout'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    wrapper: {
        width: 600,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    form: {
        marginTop: theme.spacing(4),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(4),
        width: '100%',
    },
}))

const LoginPage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const { setUser } = useContext(AuthenticationContext)

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value)
    }

    const handleLogin = (event: any) => {
        if (email && password) {
            setLoading(true)
            firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then((user) => {
                    setUser(user)
                    history.push('/')
                })
                .catch((error) => {
                    setLoading(false)
                    alert(error.message)
                })
        } else {
            alert('Please fill in your credentials')
        }
    }

    return (
        <Layout>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <Typography variant="h2" className={classes.header}>
                        Login
                    </Typography>
                    <div className={classes.form}>
                        <TextField
                            id="email"
                            name="email"
                            label="Email"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <TextField
                            id="password"
                            name="password"
                            label="Password"
                            variant="outlined"
                            size="small"
                            type="password"
                            className={classes.textField}
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={handleLogin}
                            disabled={loading}
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default LoginPage
