import { Component, Input, Node, Output } from 'rete'
import { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data'

import PersonMultiSelectNode from '../nodes/PersonMultiSelectNode'
import { getNewNodeId } from '../../../utils/nodes'
import NodeSocket from '../sockets/NodeSocket'
import QuestionIdControl from '../controls/QuestionIdControl'
import TextControl from '../controls/TextControl'
import NumberControl from '../controls/NumberControl'

class PersonMultiSelectComponent extends Component {
    constructor() {
        super('PERSON-SELECT')
        //@ts-ignore
        this.data.component = PersonMultiSelectNode
    }

    //@ts-ignore
    builder = async (node: Node) => {
        node.data.questionId = node.data.questionId || getNewNodeId(this.editor)

        const input = new Input('in', '', NodeSocket, true)
        const questionId = new QuestionIdControl(this.editor, 'questionId', node, 'Question ID')
        const questionText = new TextControl(this.editor, 'questionText', node)
        const min = new NumberControl(this.editor, 'min', node, 'Min selections')
        const max = new NumberControl(this.editor, 'max', node, 'Max selections')
        const output = new Output('out', '', NodeSocket, false)

        node.addInput(input)
            .addControl(questionId)
            .addControl(questionText)
            .addControl(min)
            .addControl(max)
            .addOutput(output)
    }

    worker = (node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs) => {}
}

export default PersonMultiSelectComponent
