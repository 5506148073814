import { Component, createRef, RefObject } from 'react'
import { Control, Node, NodeEditor } from 'rete'

export interface TextControlProps {
    value: string
    node: Node
    emitter: NodeEditor | null
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

class TextControlReactComponent extends Component<TextControlProps> {
    textInput: RefObject<HTMLTextAreaElement>

    constructor(props: any) {
        super(props)
        this.textInput = createRef<HTMLTextAreaElement>()
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.textInput && this.textInput.current) {
                this.textInput.current.style.height = 'auto'
                this.textInput.current.style.height = this.textInput.current.scrollHeight + 'px'
                if (this.props.emitter) {
                    this.props.emitter.view.updateConnections({
                        node: this.props.node,
                    })
                }
            }
        }, 1)
    }

    render() {
        return (
            <textarea
                className="text-control"
                onChange={this.props.onChange}
                value={this.props.value}
                ref={this.textInput}
            />
        )
    }
}

class TextControl extends Control {
    key: string
    render: string
    emitter: NodeEditor | null
    node: Node
    component: typeof TextControlReactComponent
    props: TextControlProps

    constructor(emitter: NodeEditor | null, key: string, node: Node) {
        super(key)
        this.render = 'react'
        this.emitter = emitter
        this.key = key
        this.node = node
        this.component = TextControlReactComponent

        const initial = (node.data[key] as string) || ''
        node.data[key] = initial

        this.props = {
            value: initial,
            node: this.node,
            emitter: this.emitter,
            onChange: (event) => {
                // @ts-ignore
                if (emitter.preview) return
                this.setValue(event!.target!.value)
                event.target.style.height = 'auto'
                event.target.style.height = event!.target!.scrollHeight + 'px'
                this.emitter?.view.updateConnections({ node })
            },
        }
    }

    setValue(val: string) {
        this.props.value = val
        this.putData(this.key, val)
        //@ts-ignore
        this.update()
    }
}

export default TextControl
