import React, { useState, useEffect, useContext } from 'react'
import firebase from 'firebase'
import { makeStyles } from '@material-ui/core/styles'

import ProgressIndicator from './ProgressIndicator'
import ErrorDisplay from './ErrorDisplay'
import { ConversationContext } from '../context/ConversationContext'
import { AuthenticationContext } from '../context/AuthenticationProvider'
import Editor from '../rete/Editor'
import ToolBar from './ToolBar'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
}))

type Props = {
    conversationId: string
    preview: boolean
}

const ConversationLoader: React.FC<Props> = ({ conversationId, preview }) => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [conversation, setConversation] = useState<any>(null)
    const { authenticated } = useContext(AuthenticationContext)

    useEffect(() => {
        const db = firebase.firestore()
        setLoading(true)
        setError(null)
        db.collection('conversations')
            .doc(conversationId)
            .get()
            .then((document) => {
                if (document.exists) {
                    const data = document.data()
                    setConversation({ id: conversationId, ...data })
                    setError(null)
                    setLoading(false)
                } else {
                    setConversation(null)
                    setError(`Conversation with ID = '${conversationId}' does not exist.`)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setConversation(null)
                setError(JSON.stringify(error))
                setLoading(false)
            })
    }, [conversationId])

    const renderContent = () => {
        if (loading) return <ProgressIndicator text="Loading conversation..." />
        if (error) return <ErrorDisplay text={error} />
        if (conversation) {
            // conversation locking logic (enables or disables preview mode where conversation manipulation is prohibited)
            let finalPreview = false
            // when conversation is locked we are always in preview mode
            if (conversation.status === 'locked') {
                finalPreview = true
            }
            // when conversation is closed and user is not authenticated then we are in preview mode
            if (conversation.status === 'closed' && !authenticated) {
                finalPreview = true
            }
            // check param from preview route last, no matter what, in preview page we are always in preview
            if (preview) {
                finalPreview = true
            }

            return (
                <ConversationContext.Provider value={{ conversation, setConversation }}>
                    <ToolBar preview={finalPreview} />
                    <Editor preview={finalPreview} />
                </ConversationContext.Provider>
            )
        }
        return null
    }

    return <div className={classes.container}>{renderContent()}</div>
}

export default ConversationLoader
