import Papa from 'papaparse'
import AreaPlugin from 'rete-area-plugin'
import {
    EndComponent,
    MultiSelectComponent,
    PersonMultiSelectComponent,
    SelectComponent,
    TellComponent,
    TextComponent,
} from '../components/rete/components'
import { getSanitizedQuestionId, getNodesWithoutInputConnections, swapStartNodeToBeginning } from './nodes'

/*
INDEX OF INFO IN CSV FILE
0: "topicID"
1: "Notes"
2: "QuestionID"
3: "QuestionText"
4: "Type"
5: "AnswerRouting"
6: "AnonymityRouting"
7: "RouteTo"
8: "Answers"
9: "NotificationSubject"
10: "NotificationEmail"
11: "Language"
12: "SkipInExpress"
13: "Reporting"
*/

export const readImportFile = (editor, conversation) => {
    const input = document.createElement('input')
    input.type = 'file'

    input.onchange = (e) => {
        if (e && e.target) {
            const file = e.target.files[0]

            const reader = new FileReader()
            reader.readAsText(file, 'UTF-8')

            reader.onload = (readerEvent) => {
                if (readerEvent && readerEvent.target && readerEvent.target.result) {
                    const content = readerEvent.target.result
                    constructNodesFromCSV(content, editor, conversation)
                }
            }
        }
    }

    input.click()
}

export const writeImportFile = (editor, conversation) => {
    const startNodes = getNodesWithoutInputConnections(editor)

    if (startNodes.length !== 1) {
        alert('Your conversation must have only one starting node (without any input connection).')
        return
    }

    const csvData = {
        // fields: [
        //   "topicID",
        //   "Notes",
        //   "QuestionID",
        //   "QuestionText",
        //   "Type",
        //   "AnswerRouting",
        //   "AnonymityRouting",
        //   "RouteTo",
        //   "Answers",
        //   "NotificationSubject",
        //   "NotificationEmail",
        //   "Language",
        //   "SkipInExpress",
        // ],
        data: [],
    }

    const editorData = editor.toJSON()
    const editorNodes = swapStartNodeToBeginning(Object.values(editorData.nodes))

    console.log(editorNodes)

    if (editorData) {
        editorNodes.forEach((node) => {
            if (node.name === 'SELECT') {
                const options = Object.keys(node.data).filter((key) => key.startsWith('opt-'))
                const specificKeys = []
                const defaultKeys = []
                Object.keys(node.outputs).forEach((k) => {
                    if (node.outputs[k].connections.length > 0) {
                        // eslint-disable-next-line
                        if (node.outputs[k].connections[0].data == 'd') {
                            defaultKeys.push(k)
                        } else {
                            specificKeys.push(k)
                        }
                    }
                })
                specificKeys.forEach((key) => {
                    const csvLine = createBaseCsvLineFromNode(node, conversation)
                    const otherNode = findOutputNodeForOptionInEditor(node, key, editor)
                    csvLine[5] = key.replace('out-', '')
                    csvLine[8] = options.map((key) => node.data[key]).join('|')
                    if (otherNode) {
                        csvLine[7] =
                            (conversation.topic || conversation.topicId) +
                            getSanitizedQuestionId(otherNode.data.questionId || otherNode.id)
                    }
                    csvData.data.push(csvLine)
                })
                if (defaultKeys.length > 0) {
                    const key = defaultKeys[0]
                    const csvLine = createBaseCsvLineFromNode(node, conversation)
                    const otherNode = findOutputNodeForOptionInEditor(node, key, editor)
                    csvLine[5] = ''
                    csvLine[8] = options.map((key) => node.data[key]).join('|')
                    if (otherNode) {
                        csvLine[7] =
                            (conversation.topic || conversation.topicId) +
                            getSanitizedQuestionId(otherNode.data.questionId || otherNode.id)
                    }
                    csvData.data.push(csvLine)
                }
            } else if (node.name === 'MULTISELECT') {
                const options = Object.keys(node.data).filter((key) => key.startsWith('opt-'))
                const specificKeys = []
                const defaultKeys = []
                Object.keys(node.outputs)
                    .reverse()
                    .forEach((k) => {
                        if (node.outputs[k].connections.length > 0) {
                            // eslint-disable-next-line
                            if (node.outputs[k].connections[0].data == 'd') {
                                defaultKeys.push(k)
                            } else {
                                specificKeys.push(k)
                            }
                        }
                    })
                specificKeys.forEach((key) => {
                    const csvLine = createBaseCsvLineFromNode(node, conversation)
                    csvLine[4] = csvLine[4] + ` ${node.data.min}-${node.data.max}`
                    const otherNode = findOutputNodeForOptionInEditor(node, key, editor)
                    csvLine[5] = `INANY ${key.replace('out-', '')}`
                    csvLine[8] = options.map((key) => node.data[key]).join('|')
                    if (otherNode) {
                        csvLine[7] =
                            (conversation.topic || conversation.topicId) +
                            getSanitizedQuestionId(otherNode.data.questionId || otherNode.id)
                    }
                    csvData.data.push(csvLine)
                })
                if (defaultKeys.length > 0) {
                    const key = defaultKeys[0]
                    const csvLine = createBaseCsvLineFromNode(node, conversation)
                    csvLine[4] = csvLine[4] + ` ${node.data.min}-${node.data.max}`
                    const otherNode = findOutputNodeForOptionInEditor(node, key, editor)
                    csvLine[5] = ''
                    csvLine[8] = options.map((key) => node.data[key]).join('|')
                    if (otherNode) {
                        csvLine[7] =
                            (conversation.topic || conversation.topicId) +
                            getSanitizedQuestionId(otherNode.data.questionId || otherNode.id)
                    }
                    csvData.data.push(csvLine)
                }
            } else if (node.name === 'TEXT') {
                const csvLine = createBaseCsvLineFromNode(node, conversation)
                const otherNode = findOutputNodeInEditor(node, 'out', editor)
                if (otherNode) {
                    csvLine[7] =
                        (conversation.topic || conversation.topicId) +
                        getSanitizedQuestionId(otherNode.data.questionId || otherNode.id)
                }
                csvData.data.push(csvLine)
            } else if (node.name === 'TELL') {
                const csvLine = createBaseCsvLineFromNode(node, conversation)
                const otherNode = findOutputNodeInEditor(node, 'out', editor)
                if (otherNode) {
                    csvLine[7] =
                        (conversation.topic || conversation.topicId) +
                        getSanitizedQuestionId(otherNode.data.questionId || otherNode.id)
                }
                csvData.data.push(csvLine)
            } else if (node.name === 'PERSON-SELECT') {
                const csvLine = createBaseCsvLineFromNode(node, conversation)
                csvLine[4] = csvLine[4] + ` ${node.data.min}-${node.data.max}`
                const otherNode = findOutputNodeInEditor(node, 'out', editor)
                if (otherNode) {
                    csvLine[7] =
                        (conversation.topic || conversation.topicId) +
                        getSanitizedQuestionId(otherNode.data.questionId || otherNode.id)
                }
                csvData.data.push(csvLine)
            } else if (node.name === 'END') {
                const csvLine = createBaseCsvLineFromNode(node, conversation)
                csvLine[4] = 'ENDING'
                csvLine[7] = 'END'
                csvData.data.push(csvLine)
            }
        })

        csvData.data[0][1] = `${conversation.name} [CUSTOM CONVERSATION]`

        var csv = Papa.unparse(csvData, {
            quotes: false,
            delimiter: '\t',
        })
        var csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        var csvURL = null
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(
                csvBlob,
                `con_${
                    conversation.topic || conversation.topicId
                }_${conversation.language.toLowerCase()}_${conversation.name.replace(/ /g, '_')}.csv`
            )
        } else {
            csvURL = window.URL.createObjectURL(csvBlob)
        }
        var tempLink = document.createElement('a')
        tempLink.href = csvURL
        tempLink.setAttribute(
            'download',
            `con_${
                conversation.topic || conversation.topicId
            }_${conversation.language.toLowerCase()}_${conversation.name.replace(/ /g, '_')}.csv`
        )
        tempLink.click()
    }
}

const findOutputNodeForOptionInEditor = (node, optionKey, editor) => {
    const outputKey = optionKey.replace('opt', 'out')
    return editor.nodes.find((n) => {
        if (node.outputs[outputKey].connections[0]) {
            // eslint-disable-next-line
            return n.id == node.outputs[outputKey].connections[0].node
        }
        return false
    })
}

const findOutputNodeInEditor = (node, out, editor) => {
    return editor.nodes.find((n) => {
        if (node.outputs[out].connections[0]) {
            // eslint-disable-next-line
            return n.id == node.outputs[out].connections[0].node
        }
        return false
    })
}

const createBaseNodeData = (line, conversation) => {
    const len = conversation.topic ? conversation.topic.length : conversation.topicId.length

    let questionId = getSanitizedQuestionId(line[2].substring(len))

    const data = {
        notes: line[1],
        questionId,
        questionText: line[3],
        type: line[4],
        anonymityRouting: line[6],
        skipInExpress: line[12],
        reporting: line[13] ? line[13] : '',
    }

    // console.log(data);
    return data
}

const createBaseCsvLineFromNode = (node, conversation) => {
    //heuristic for skipInExpress
    let skipInExpress = 'FALSE'
    if (node.data.skipInExpress === 'TRUE') {
        //either it has been set already
        skipInExpress = 'TRUE'
    } else if (node.name === 'TELL') {
        // or derive from node.name
        skipInExpress = 'TRUE'
    }

    let nodeName = node.name
    if (nodeName === 'TEXT') {
        nodeName = node.data.checkbox ? 'FREETEXT' : 'OPTIONAL-TEXT'
    } else if (nodeName === 'PERSON-SELECT') {
        nodeName = 'PERSON-MULTISELECT'
    }

    return [
        conversation.topic || conversation.topicId,
        node.data.notes || '',
        (conversation.topic || conversation.topicId) + getSanitizedQuestionId(node.data.questionId || node.id),
        node.data.questionText.trim() || '',
        nodeName,
        '',
        node.data.anonymityRouting || '',
        '',
        '',
        '',
        '',
        conversation.language,
        skipInExpress,
        node.data.reporting || '',
    ]
}

const constructNodesFromCSV = async (content, editor, conversation) => {
    const data = Papa.parse(content, {
        delimiter: '\t',
    })
    const lines = data.data
    // lines.shift();

    editor.clear()
    const editorData = await editor.toJSON()
    localStorage.setItem('editorData', JSON.stringify(editorData))

    // first creeate nodes
    let iterator = 0
    while (iterator < lines.length) {
        const line = lines[iterator]

        if (line && line.length > 0) {
            switch (line[4].trim()) {
                case 'TELL': {
                    // only one line
                    const tellNodeData = createBaseNodeData(line, conversation)
                    const tellNode = await new TellComponent().createNode(tellNodeData)
                    editor.addNode(tellNode)
                    iterator += 1
                    break
                }
                case 'FREETEXT': {
                    // only one line
                    const textNodeData = createBaseNodeData(line, conversation)
                    textNodeData.checkbox = true
                    const textNode = await new TextComponent().createNode(textNodeData)
                    editor.addNode(textNode)
                    iterator += 1
                    break
                }
                case 'OPTIONAL-TEXT': {
                    // only one line
                    const textNodeData = createBaseNodeData(line, conversation)
                    textNodeData.checkbox = false
                    const textNode = await new TextComponent().createNode(textNodeData)
                    editor.addNode(textNode)
                    iterator += 1
                    break
                }
                case 'ENDING': {
                    // only one line
                    const endNodeData = createBaseNodeData(line, conversation)
                    const endNode = await new EndComponent().createNode(endNodeData)
                    editor.addNode(endNode)
                    iterator++
                    break
                }
                case 'SELECT': {
                    // can be multiple lines,
                    // can have default option when no option numbering is designated
                    const questionId = line[2]
                    let numberOfLines = 1
                    // look-ahead how mnay lines this SELECT is defined on
                    // eslint-disable-next-line
                    while (lines[iterator + numberOfLines][2] == questionId) {
                        numberOfLines++
                    }
                    const selectNodeData = createBaseNodeData(line, conversation)
                    const answers = line[8].split('|')
                    const options = new Map()
                    answers.forEach((answer, index) => {
                        selectNodeData[`opt-${index + 1}`] = answer
                        options.set(`opt-${index + 1}`)
                    })
                    selectNodeData.options = options
                    const selectNode = await new SelectComponent().createNode(selectNodeData)
                    editor.addNode(selectNode)
                    iterator += numberOfLines
                    break
                }
                default: {
                    // need to handle multiselect here since it only starts with this word
                    // can be multiple lines,
                    // can have default option when no option numbering is designated
                    if (line && line[4].startsWith('MULTISELECT')) {
                        const questionId = parseInt(line[2])
                        let numberOfLines = 1
                        // look-ahead how mnay lines this SELECT is defined on
                        // eslint-disable-next-line
                        while (lines[iterator + numberOfLines][2] === questionId) {
                            numberOfLines++
                        }
                        const multiSelectData = createBaseNodeData(line, conversation)
                        multiSelectData.min = line[4].split(' ')[1].split('-')[0]
                        multiSelectData.max = line[4].split(' ')[1].split('-')[1]
                        const options = new Map()
                        line[8].split('|').forEach((answer, index) => {
                            multiSelectData[`opt-${index + 1}`] = answer
                            options.set(`opt-${index + 1}`)
                        })
                        multiSelectData.options = options
                        const multiSelectNode = await new MultiSelectComponent().createNode(multiSelectData)
                        editor.addNode(multiSelectNode)
                        iterator += numberOfLines
                        break
                    } else if (line && line[4].startsWith('PERSON-MULTISELECT')) {
                        const personMultiselectNodeData = createBaseNodeData(line, conversation)
                        personMultiselectNodeData.min = line[4].split(' ')[1].split('-')[0]
                        personMultiselectNodeData.max = line[4].split(' ')[1].split('-')[1]
                        const personMultiselectNode = await new PersonMultiSelectComponent().createNode(
                            personMultiselectNodeData
                        )
                        editor.addNode(personMultiselectNode)
                        iterator += 1
                        break
                    } else {
                        iterator = 1000000
                        alert(
                            'File has unsupported column structure, please review the file contents, fix it and try again!'
                        )
                        return
                    }
                }
            }
        }
    }

    // second create connections
    iterator = 0
    const len = conversation.topic ? conversation.topic.length : conversation.topicId.length

    while (iterator < lines.length) {
        const line = lines[iterator]

        switch (line[4].trim()) {
            case 'TELL': // fallthrough
            case 'FREETEXT': // fallthrough
            case 'OPTIONAL-TEXT': // fallthrough
            case 'ENDING': {
                const startNode = editor.nodes.find((n) => {
                    // eslint-disable-next-line
                    return n.data.questionId == line[2].substring(len)
                })
                const endNode = editor.nodes.find((n) => {
                    // eslint-disable-next-line
                    return n.data.questionId == line[7].substring(len)
                })
                if (startNode && endNode) {
                    editor.connect(startNode.outputs.get('out'), endNode.inputs.get('in'))
                }
                iterator++
                break
            }
            case 'SELECT': {
                const questionId = line[2]
                let numberOfLines = 1
                // look-ahead how mnay lines this SELECT is defined on
                // eslint-disable-next-line
                while (lines[iterator + numberOfLines][2] == questionId) {
                    numberOfLines++
                }
                for (let i = 0; i < numberOfLines; i++) {
                    const tmpLine = lines[iterator + i]
                    const startNode = editor.nodes.find((n) => {
                        // eslint-disable-next-line
                        return n.data.questionId == tmpLine[2].substring(len)
                    })
                    const endNode = editor.nodes.find((n) => {
                        // eslint-disable-next-line
                        return n.data.questionId == tmpLine[7].substring(len)
                    })
                    if (startNode && endNode) {
                        if (tmpLine[5]) {
                            // specific route option
                            editor.connect(
                                startNode.outputs.get(`out-${tmpLine[5]}`),
                                endNode.inputs.get('in'),
                                tmpLine[5]
                            )
                        } else {
                            // default route option
                            startNode.data.options.forEach((v, k) => {
                                const outKey = k.replace('opt', 'out')
                                if (startNode.outputs.get(outKey).connections.length === 0) {
                                    editor.connect(startNode.outputs.get(outKey), endNode.inputs.get('in'), 'd')
                                }
                            })
                        }
                    }
                }
                iterator += numberOfLines
                break
            }
            default: {
                if (line && line[4].startsWith('MULTISELECT')) {
                    const questionId = line[2]
                    let numberOfLines = 1
                    // look-ahead how mnay lines this SELECT is defined on
                    // eslint-disable-next-line
                    while (lines[iterator + numberOfLines][2] == questionId) {
                        numberOfLines++
                    }
                    for (let i = 0; i < numberOfLines; i++) {
                        const tmpLine = lines[iterator + i]
                        const startNode = editor.nodes.find((n) => {
                            // eslint-disable-next-line
                            return n.data.questionId === tmpLine[2].substring(len)
                        })
                        const endNode = editor.nodes.find((n) => {
                            // eslint-disable-next-line
                            return n.data.questionId === tmpLine[7].substring(len)
                        })
                        if (startNode && endNode) {
                            if (tmpLine[5]) {
                                // specific route option
                                const splittedRoute = tmpLine[5].split(' ')
                                const index = splittedRoute.length > 1 ? splittedRoute[1] : splittedRoute[0]
                                editor.connect(startNode.outputs.get(`out-${index}`), endNode.inputs.get('in'), index)
                            } else {
                                // default route option
                                startNode.data.options.forEach((v, k) => {
                                    const outKey = k.replace('opt', 'out')
                                    if (startNode.outputs.get(outKey).connections.length === 0) {
                                        editor.connect(startNode.outputs.get(outKey), endNode.inputs.get('in'), 'd')
                                    }
                                })
                            }
                        }
                    }
                    iterator += numberOfLines
                } else if (line && line[4].startsWith('PERSON-MULTISELECT')) {
                    const startNode = editor.nodes.find((n) => {
                        // eslint-disable-next-line
                        return n.data.questionId == line[2].substring(len)
                    })
                    const endNode = editor.nodes.find((n) => {
                        // eslint-disable-next-line
                        return n.data.questionId == line[7].substring(len)
                    })
                    if (startNode && endNode) {
                        editor.connect(startNode.outputs.get('out'), endNode.inputs.get('in'))
                    }
                    iterator++
                }
                break
            }
        }
    }

    editor.trigger('process')
    editor.trigger('arrange', { node: editor.nodes[0] })
    AreaPlugin.zoomAt(editor, editor.nodes)
}
