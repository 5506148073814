import { Component, Input, Node, Output } from 'rete'
import { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data'

import MultiSelectNode from '../nodes/MultiSelectNode'
import { getNewNodeId } from '../../../utils/nodes'
import NodeSocket from '../sockets/NodeSocket'
import QuestionIdControl from '../controls/QuestionIdControl'
import TextControl from '../controls/TextControl'
import NumberControl from '../controls/NumberControl'
import AddOptionControl from '../controls/AddOptionControl'
import RemoveOptionControl from '../controls/RemoveOptionControl'
import OptionControl from '../controls/OptionControl'

class MultiSelectComponent extends Component {
    constructor() {
        super('MULTISELECT')
        //@ts-ignore
        this.data.component = MultiSelectNode
    }

    //@ts-ignore
    builder = async (node: Node) => {
        node.data.questionId = node.data.questionId || getNewNodeId(this.editor)

        const input = new Input('in', '', NodeSocket, true)
        const questionId = new QuestionIdControl(this.editor, 'questionId', node, 'Question ID')
        const questionText = new TextControl(this.editor, 'questionText', node)
        const min = new NumberControl(this.editor, 'min', node, 'Min selections')
        const max = new NumberControl(this.editor, 'max', node, 'Max selections')
        const addOption = new AddOptionControl(this.editor, 'add', node)
        const removeOption = new RemoveOptionControl(this.editor, 'remove', node)

        node.addInput(input)
            .addControl(questionId)
            .addControl(questionText)
            .addControl(min)
            .addControl(max)
            .addControl(addOption)
            .addControl(removeOption)

        node.data.iterator = 1
        for (const key in node.data) {
            if (key.indexOf('opt-') > -1) {
                const output = new Output(`out-${node.data.iterator}`, '', NodeSocket, false)
                const option = new OptionControl(
                    this.editor,
                    `opt-${node.data.iterator}`,
                    node,
                    node.data[key] as string
                )
                node.addOutput(output).addControl(option)
                node.data['iterator'] = (node.data['iterator'] as number) + 1
            }
        }
    }

    worker = (node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs) => {}
}

export default MultiSelectComponent
