import React, { useContext, useState } from 'react'
import firebase from 'firebase'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import Layout from '../common/Layout'
import { languages } from '../../utils/options'
import { DataContext } from '../context/DataProvider'
import { Organisation } from '../../models/Organisation'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 600,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    paragraph: {
        marginBottom: theme.spacing(2),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(4),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(4),
    },
}))

const ConversationCreatePage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const { organisations } = useContext(DataContext)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [topic, setTopic] = useState('')
    const [organisation, setOrganisation] = useState('')
    const [language, setLanguage] = useState('en')
    const [status, setStatus] = useState('open')

    const handleNameChange = (event: any) => {
        setName(event.target.value)
    }

    const handleTopicChange = (event: any) => {
        setTopic(event.target.value)
    }

    const handleOrganisationChange = (event: any) => {
        setOrganisation(event.target.value)
    }

    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value)
    }

    const handleStatusChange = (event: any) => {
        setStatus(event.target.value)
    }

    const handleCreateConversation = (event: any) => {
        if (name && topic && language && organisation) {
            setLoading(true)
            const db = firebase.firestore()
            const data = JSON.stringify({ id: 'demo@0.1.0', nodes: {} })
            const conversation = {
                name,
                topic,
                organisation,
                language,
                data,
                topicId: topic,
                archived: false,
                status,
            }
            db.collection('conversations')
                .add(conversation)
                .then((docRef) => {
                    setLoading(false)
                    history.push(`/conversations/edit/${docRef.id}`)
                })
                .catch((_) => {
                    setLoading(false)
                })
        } else {
            alert('Please fill in all fields to create a conversation')
        }
    }

    return (
        <Layout>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <Typography variant="h2" className={classes.header}>
                        Create new conversation
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Please fill all the fields below in order to create a new conversation.
                    </Typography>
                    <div className={classes.form}>
                        <TextField
                            id="name"
                            name="name"
                            label="Name"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={name}
                            onChange={handleNameChange}
                        />
                        <TextField
                            id="topic"
                            name="topic"
                            label="Topic"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={topic}
                            onChange={handleTopicChange}
                        />
                        <TextField
                            select
                            id="organisation"
                            name="organisation"
                            label="Organisation"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={organisation}
                            onChange={handleOrganisationChange}
                        >
                            {organisations.map((option: Organisation) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            id="language"
                            name="language"
                            label="Language"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={language}
                            onChange={handleLanguageChange}
                        >
                            {languages.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            id="status"
                            name="status"
                            label="Status"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={status}
                            onChange={handleStatusChange}
                        >
                            <MenuItem value={'open'}>Open</MenuItem>
                            <MenuItem value={'closed'}>Closed</MenuItem>
                            <MenuItem value={'locked'}>Locked</MenuItem>
                        </TextField>
                        <div className={classes.buttons}>
                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() => history.goBack()}
                                disabled={loading}
                            >
                                Back
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleCreateConversation}
                                disabled={loading}
                            >
                                Create conversation
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ConversationCreatePage
