import { Control, Node, NodeEditor } from 'rete'
import Icon from '@material-ui/icons/Remove'

interface RemoveOptionControlProps {
    onRemove: () => void
}

class RemoveOptionControl extends Control {
    key: string
    render: string
    emitter: NodeEditor | null
    component: (props: RemoveOptionControlProps) => JSX.Element
    props: RemoveOptionControlProps

    static component = ({ onRemove }: RemoveOptionControlProps) => {
        return (
            <div className="remove-option-control">
                <button type="button" className="remove-option-control-button" onClick={(e) => onRemove()}>
                    <Icon className="remove-option-control-icon" /> Remove last option
                </button>
            </div>
        )
    }

    constructor(emitter: NodeEditor | null, key: string, node: Node) {
        super(key)
        this.render = 'react'
        this.emitter = emitter
        this.key = key
        this.component = RemoveOptionControl.component

        this.props = {
            onRemove: () => {
                // @ts-ignore
                if (emitter.preview) return
                if (emitter && (node.data['iterator'] as number) > 1) {
                    const control = node.controls.get(`opt-${(node.data['iterator'] as number) - 1}`)
                    const output = node.outputs.get(`out-${(node.data['iterator'] as number) - 1}`)
                    if (output && control) {
                        if (output.hasConnection()) {
                            output.connections.forEach((connection) => {
                                emitter.removeConnection(connection)
                            })
                        }
                        node.removeControl(control)
                        node.removeOutput(output)
                        delete node.data[`opt-${(node.data['iterator'] as number) - 1}`]
                        node.data['iterator'] = (node.data['iterator'] as number) - 1
                        node.update()
                    }
                }
            },
        }
    }
}

export default RemoveOptionControl
