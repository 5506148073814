export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export async function createNode(component, { data = {}, meta = {}, x = 0, y = 0, qId = 0 }) {
    const newData = deepCopy(data)
    newData.questionId = qId
    const node = await component.createNode(newData)

    node.meta = Object.assign(deepCopy(meta), node.meta)
    node.position[0] = x
    node.position[1] = y

    return node
}

export function getNewNodeId(editor) {
    if (editor.nodes && editor.nodes.length > 0) {
        let highestId = 0
        editor.nodes.forEach((node) => {
            const nodeId = parseInt(node.data.questionId)
            if (nodeId > highestId) {
                highestId = nodeId
            }
        })
        highestId++
        return highestId.toString().length > 1 ? '' + highestId : '0' + highestId
    } else {
        return '01'
    }
}

export function verifyEditorNodes(editor) {
    let result = true

    if (editor.nodes && editor.nodes.length > 0) {
        const questionIdMap = {}
        const possibleDuplicateNodes = []

        editor.nodes.forEach((node) => {
            if (!questionIdMap[node.data.questionId]) {
                questionIdMap[node.data.questionId] = 1
            } else {
                questionIdMap[node.data.questionId] += 1
                possibleDuplicateNodes.push(node)
                result = false
            }

            //test if all nodes has exactly 1 outlink on outputs
            for (let output of node.outputs.values()) {
                if (output.connections.length < 1) {
                    console.log('ERROR: output links missing in questionId=' + node.data.questionId)
                    result = false
                } else if (output.connections.length > 1) {
                    console.log('ERROR: too many output links in questionId=' + node.data.questionId)
                    result = false
                }
            }

            //switch output mode into single output
            for (let output of node.outputs.values()) {
                for (let connection of output.connections.values()) {
                    if (connection.output.multipleConnections === true) {
                        console.log(
                            'Warning: You had multipleConnections allowed in questionId=' +
                                node.data.questionId +
                                '. Fixed.'
                        )
                    }
                    connection.output.multipleConnections = false //let's make sure all the nodes are singleConnections
                }
            }
        })
        const editorNodesCount = editor.nodes.length
        const distinctIdsCount = Object.keys(questionIdMap).length

        console.log('')
        console.log('===== EDITOR DIAGNOSTICS ======')

        console.log('Editor nodes count = ', editorNodesCount)
        console.log('Distinct IDs count = ', distinctIdsCount)
        if (editorNodesCount !== distinctIdsCount) {
            console.log('ERROR - Seems you have duplicate IDs!')
            console.log('Question Ids map = ', questionIdMap)
            console.log('Possible nodes to fix = ', possibleDuplicateNodes)
        } else {
            console.log('OK - Duplicate question IDs.')
            console.log('HINT - You can also use automatic questionId rewrite functionality from the diagnose menu.')
        }
    }
    return result
}

export function fixEditorNodes(editor) {
    if (editor.nodes && editor.nodes.length > 0) {
        const questionIdMap = {}
        const possibleDuplicateNodes = []

        editor.nodes.forEach((node) => {
            if (!questionIdMap[node.data.questionId]) {
                questionIdMap[node.data.questionId] = 1
            } else {
                questionIdMap[node.data.questionId] += 1
                possibleDuplicateNodes.push(node)
            }
        })

        console.log('')
        console.log('===== EDITOR FIX ======')
        console.log('Possible nodes that need fix = ', possibleDuplicateNodes)

        possibleDuplicateNodes.forEach((node) => {
            const newQuestionnId = getNewNodeId(editor)
            node.data.questionId = newQuestionnId
            console.log('Fixed node = ', node)
        })

        console.log('OK - Fixing finished.')
    }
}

export function getSanitizedQuestionId(questionId) {
    if (questionId.toString().length > 1) {
        return '' + questionId
    } else {
        return '0' + questionId
    }
}

export function getNodesWithoutInputConnections(editor) {
    const nodes = []
    if (editor.nodes && editor.nodes.length > 0) {
        editor.nodes.forEach((node) => {
            const input = node.inputs.get('in')
            if (input.connections.length < 1) {
                nodes.push(node)
            }
        })
    }
    return nodes
}

export function getEndingNodes(editor) {
    const nodes = []
    if (editor.nodes && editor.nodes.length > 0) {
        editor.nodes.forEach((node) => {
            if (node.name === 'END') {
                nodes.push(node)
            }
        })
    }
    return nodes
}

export function getSelectNodes(editor) {
    const nodes = []
    if (editor.nodes && editor.nodes.length > 0) {
        editor.nodes.forEach((node) => {
            if (node.name === 'SELECT') {
                nodes.push(node)
            }
        })
    }
    return nodes
}

export function getMultiselectNodes(editor) {
    const nodes = []
    if (editor.nodes && editor.nodes.length > 0) {
        editor.nodes.forEach((node) => {
            if (node.name === 'MULTISELECT') {
                nodes.push(node)
            }
        })
    }
    return nodes
}

export function getPersonSelectNodes(editor) {
    const nodes = []
    if (editor.nodes && editor.nodes.length > 0) {
        editor.nodes.forEach((node) => {
            if (node.name === 'PERSON-SELECT') {
                nodes.push(node)
            }
        })
    }
    return nodes
}

export function getAllNodes(editor) {
    return editor.nodes
}

export function swapStartNodeToBeginning(nodes) {
    const startNode = nodes.find((node) => node.inputs['in'].connections.length === 0)
    const otherNodes = nodes.filter((node) => node.inputs['in'].connections.length > 0)
    const result = [startNode, ...otherNodes]
    return result
}
