import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import Layout from '../common/Layout'
import ProgressIndicator from '../common/ProgressIndicator'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { DataContext } from '../context/DataProvider'
import { Organisation } from '../../models/Organisation'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    table: {
        width: '100%',
    },
}))

const OrganisationListPage: React.FC = () => {
    const classes = useStyles()
    const { organisations, loadingOrganisations } = useContext(DataContext)

    return (
        <Layout>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <div className={classes.header}>
                        <Typography variant="h2">Organisation list</Typography>
                        <Button
                            component={Link}
                            to={'/organisations/create'}
                            color="primary"
                            variant="outlined"
                        >
                            <AddCircleIcon />
                            &nbsp;&nbsp;Create new organisation
                        </Button>
                    </div>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loadingOrganisations ? (
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            colSpan={2}
                                        >
                                            <ProgressIndicator text="Loading organisations..." />
                                        </TableCell>
                                    </TableRow>
                                ) : organisations.length > 0 ? (
                                    organisations.map(
                                        (organisation: Organisation) => (
                                            <TableRow
                                                key={organisation.id}
                                                hover
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {organisation.name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        component={Link}
                                                        to={`/organisations/${organisation.id}`}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            colSpan={2}
                                            align="center"
                                        >
                                            No organisations have been found,
                                            try clearing your filter or
                                            refrshing data via admin menu.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Layout>
    )
}

export default OrganisationListPage
