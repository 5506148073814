import React from 'react'
import { Node, Socket, Control } from 'rete-react-render-plugin'
import Icon from '@material-ui/icons/List'

import QuestionIdControl from '../controls/QuestionIdControl'
import TextControl from '../controls/TextControl'
import OptionControl from '../controls/OptionControl'
import AddOptionControl from '../controls/AddOptionControl'
import RemoveOptionControl from '../controls/RemoveOptionControl'

export class SelectNode extends Node {
    render() {
        const { node, bindSocket, bindControl } = this.props
        const { outputs, controls, inputs, selected } = this.state
        const diagnosisMode = JSON.parse(localStorage.getItem('diagnosisMode') || 'false')

        return (
            <div className={`node ${selected}`}>
                <div className="title">
                    <Icon />
                    <span>
                        <span className="id">{!diagnosisMode ? `${node.data.questionId} - ` : ''}</span>
                        {node.name}
                    </span>
                </div>
                {/* Inputs */}
                {inputs.map((input) => (
                    <div className="input" key={input.key}>
                        <Socket type="input" socket={input.socket} io={input} innerRef={bindSocket} />
                        {!input.showControl() && <div className="input-title">{input.name}</div>}
                        {input.showControl() && (
                            <Control
                                className="input-control"
                                key={input.key}
                                control={input.control}
                                innerRef={bindControl}
                            />
                        )}
                    </div>
                ))}
                {/* Controls */}
                {controls
                    .filter(
                        (control) =>
                            control instanceof TextControl ||
                            control instanceof AddOptionControl ||
                            control instanceof QuestionIdControl
                    )
                    .map((control) => {
                        if (!diagnosisMode && control.key === 'questionId') return null
                        return (
                            <Control className="control" key={control.key} control={control} innerRef={bindControl} />
                        )
                    })}
                {controls
                    .filter((control) => control instanceof OptionControl)
                    .map((control) => {
                        const iterator = control.key.split('-')[1]
                        const output = outputs.find((output) => output.key === `out-${iterator}`)
                        if (output) {
                            return (
                                <div key={control.key} className="inline-wrapper">
                                    <Control
                                        className="control"
                                        key={control.key}
                                        control={control}
                                        innerRef={bindControl}
                                    />
                                    <Socket
                                        className="output"
                                        key={output.key}
                                        type="output"
                                        socket={output.socket}
                                        io={output}
                                        innerRef={bindSocket}
                                    />
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}
                {
                    //@ts-ignore
                    node.data.iterator > 1 &&
                        controls
                            .filter((control) => control instanceof RemoveOptionControl)
                            .map((control) => {
                                return (
                                    <Control
                                        className="control"
                                        key={control.key}
                                        control={control}
                                        innerRef={bindControl}
                                    />
                                )
                            })
                }
            </div>
        )
    }
}

export default SelectNode
