import React from 'react'
import { Node, Socket, Control } from 'rete-react-render-plugin'
import Icon from '@material-ui/icons/SupervisorAccount'

export class PersonMultiSelectNode extends Node {
    render() {
        const { node, bindSocket, bindControl } = this.props
        const { outputs, controls, inputs, selected } = this.state
        const diagnosisMode = JSON.parse(localStorage.getItem('diagnosisMode') || 'false')

        return (
            <div className={`node ${selected}`}>
                <div className="title">
                    <Icon />
                    <span>
                        <span className="id">{!diagnosisMode ? `${node.data.questionId} - ` : ''}</span>
                        {node.name}
                    </span>
                </div>
                {/* Inputs */}
                {inputs.map((input) => (
                    <div className="input" key={input.key}>
                        <Socket type="input" socket={input.socket} io={input} innerRef={bindSocket} />
                        {!input.showControl() && <div className="input-title">{input.name}</div>}
                        {input.showControl() && (
                            <Control
                                key={input.key}
                                className="input-control"
                                control={input.control}
                                innerRef={bindControl}
                            />
                        )}
                    </div>
                ))}
                {/* Controls */}
                {controls.map((control) => {
                    if (!diagnosisMode && control.key === 'questionId') return null
                    return <Control className="control" key={control.key} control={control} innerRef={bindControl} />
                })}
                {/* Outputs */}
                {outputs.map((output) => (
                    <div className="output" key={output.key}>
                        <div className="output-title">{output.name}</div>
                        <Socket type="output" socket={output.socket} io={output} innerRef={bindSocket} />
                    </div>
                ))}
            </div>
        )
    }
}

export default PersonMultiSelectNode
