import { Control, Node, NodeEditor } from 'rete'

interface OptionControlProps {
    value: string
    onChange: (value: string) => void
}
class OptionControl extends Control {
    key: string
    render: string
    emitter: NodeEditor | null
    component: (props: OptionControlProps) => JSX.Element
    props: OptionControlProps

    static component = ({ value, onChange }: OptionControlProps) => (
        <div className="option-control">
            <input
                className="option-control-input"
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )

    constructor(emitter: NodeEditor | null, key: string, node: Node, value: string) {
        super(key)
        this.render = 'react'
        this.emitter = emitter
        this.key = key
        this.component = OptionControl.component

        this.props = {
            value,
            onChange: (v) => {
                // @ts-ignore
                if (emitter.preview) return
                this.setValue(v)
            },
        }
    }

    setValue(val: string) {
        this.props.value = val
        this.putData(this.key, val)
        //@ts-ignore
        this.update()
    }
}

export default OptionControl
