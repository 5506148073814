import { Control, Node, NodeEditor } from 'rete'

interface QuestionIdControlProps {
    label: string
    value: string
    onChange: (value: string) => void
}
class QuestionIdControl extends Control {
    key: string
    render: string
    emitter: NodeEditor | null
    component: (props: QuestionIdControlProps) => JSX.Element
    props: QuestionIdControlProps

    static component = ({ label, value, onChange }: QuestionIdControlProps) => (
        <div className="question-id-control">
            <label className="question-id-control-label">{`${label}`}</label>
            <input
                type="text"
                className="question-id-control-input"
                value={value}
                ref={(ref) => {
                    ref && ref.addEventListener('pointerdown', (e) => e.stopPropagation())
                }}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )

    constructor(emitter: NodeEditor | null, key: string, node: Node, label: string) {
        super(key)
        this.render = 'react'
        this.emitter = emitter
        this.key = key
        this.component = QuestionIdControl.component

        const initial = (node.data[key] as string) || (Math.random() * 1000000).toString()

        node.data[key] = initial
        this.props = {
            value: initial,
            label,
            //@ts-ignore
            onChange: (v) => {
                // @ts-ignore
                if (emitter.preview) return
                this.setValue(v)
            },
        }
    }

    setValue(val: string) {
        this.props.value = val.toString()
        this.putData(this.key, val.toString())
        //@ts-ignore
        this.update()
    }
}

export default QuestionIdControl
