import React, { useEffect, useRef, useContext } from 'react'
import Rete from 'rete'
import ReactRenderPlugin from 'rete-react-render-plugin'
import ConnectionPlugin from 'rete-connection-plugin'
import ContextMenuPlugin from 'rete-context-menu-plugin'
import AutoArrangePlugin from 'rete-auto-arrange-plugin'
import AreaPlugin from 'rete-area-plugin'

import { ConversationContext } from '../context/ConversationContext'
import { ModesContext } from '../context/ModesProvider'
import TellComponent from './components/TellComponent'
import TextComponent from './components/TextComponent'
import SelectComponent from './components/SelectComponent'
import MultiSelectComponent from './components/MultiSelectComponent'
import PersonMultiSelectComponent from './components/PersonMultiSelectComponent'
import EndComponent from './components/EndComponent'
import { createNode, getNewNodeId } from './../../utils/nodes'

const Editor = ({ preview }) => {
    const editorElement = useRef()
    const { conversation } = useContext(ConversationContext)
    const { diagnosisMode } = useContext(ModesContext)

    useEffect(() => {
        const createEditor = async () => {
            const components = [
                new TellComponent(),
                new TextComponent(),
                new SelectComponent(),
                new MultiSelectComponent(),
                new PersonMultiSelectComponent(),
                new EndComponent(),
            ]
            const engine = new Rete.Engine('demo@0.1.0')
            const editor = new Rete.NodeEditor('demo@0.1.0', editorElement.current)
            editor.use(ConnectionPlugin)
            editor.use(ReactRenderPlugin)

            if (!preview) {
                editor.use(AutoArrangePlugin, { margin: { x: 50, y: 50 }, depth: 0 })

                editor.use(ContextMenuPlugin, {
                    searchBar: false,
                    delay: 1000,
                    allocate(component) {
                        return ['Add']
                    },
                    items: {},
                    nodeItems: (node) => {
                        return {
                            Delete: false,
                            Clone: false,
                            'Delete Question': () => {
                                editor.removeNode(node)
                            },
                            'Clone Question': async () => {
                                const {
                                    name,
                                    position: [x, y],
                                    ...params
                                } = node
                                const component = editor.components.get(name)
                                const questionId = getNewNodeId(editor)
                                const newNode = await createNode(component, {
                                    ...params,
                                    x: x + 10,
                                    y: y + 10,
                                    qId: questionId,
                                })

                                editor.addNode(newNode)
                            },
                        }
                    },
                })
            }

            components.forEach((c) => {
                editor.register(c)
                engine.register(c)
            })

            const previousData = conversation.data
            if (previousData) {
                const parsedData = JSON.parse(previousData)
                editor.fromJSON(parsedData).then(() => {
                    editor.view.resize()
                    editor.trigger('process')
                    AreaPlugin.zoomAt(editor, editor.nodes)
                })
            }

            if (preview) {
                editor.preview = true
                editor.on('nodeselect nodeselected', async () => {
                    editor.selected.list.length = 0
                })
                editor.on('process', async () => {
                    await engine.abort()
                })
            } else {
                editor.preview = false
                editor.on('process nodecreated noderemoved connectioncreated connectionremoved', async () => {
                    await engine.abort()
                })
            }

            // registering global for easy access in other components
            window.__editor = editor
            window.__engine = engine
        }
        createEditor()
    }, [conversation, preview])
    return (
        <div className={`editor-wrapper ${diagnosisMode ? 'diagnosis' : ''}`}>
            <div id="rete-editor" className="custom-editor" ref={editorElement}></div>
        </div>
    )
}

export default Editor
