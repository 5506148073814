import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import AppBar from '../common/AppBar'

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: 93,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
    },
}))

const Layout: React.FC = ({ children }) => {
    const classes = useStyles()

    return (
        <>
            <AppBar />
            <div className={classes.container}>{children}</div>
        </>
    )
}

export default Layout
