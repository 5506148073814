import React from 'react'
import { Control, Node, NodeEditor, Output } from 'rete'
import NodeSocket from '../sockets/NodeSocket'
import OptionControl from './OptionControl'
import Icon from '@material-ui/icons/Add'

export interface AddOptionControlProps {
    onAdd: () => void
}

class AddOptionControl extends Control {
    key: string
    render: string
    emitter: NodeEditor | null
    component: (props: AddOptionControlProps) => JSX.Element
    props: AddOptionControlProps

    static component = ({ onAdd }: AddOptionControlProps) => {
        return (
            <div className="add-option-control">
                <button type="button" className="add-option-control-button" onClick={(e) => onAdd()}>
                    <Icon className="add-option-control-icon" /> Add Option
                </button>
            </div>
        )
    }

    constructor(emitter: NodeEditor | null, key: string, node: Node) {
        super(key)
        this.render = 'react'
        this.emitter = emitter
        this.key = key
        this.component = AddOptionControl.component

        this.props = {
            onAdd: () => {
                // @ts-ignore
                if (emitter.preview) return
                node.data[`opt-${node.data.iterator}`] = ''
                const output = new Output(`out-${node.data.iterator}`, '', NodeSocket)
                const option = new OptionControl(emitter, `opt-${node.data.iterator}`, node, '')
                node.addOutput(output).addControl(option)
                node.update()
                node.data['iterator'] = (node.data['iterator'] as number) + 1
            },
        }
    }
}

export default AddOptionControl
