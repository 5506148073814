import { Component, Input, Node, Output } from 'rete'
import { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data'

import TextNode from '../nodes/TextNode'
import { getNewNodeId } from '../../../utils/nodes'
import NodeSocket from '../sockets/NodeSocket'
import QuestionIdControl from '../controls/QuestionIdControl'
import TextControl from '../controls/TextControl'
import CheckboxControl from '../controls/CheckboxControl'

class TellComponent extends Component {
    constructor() {
        super('TEXT')
        //@ts-ignore
        this.data.component = TextNode
    }

    builder = async (node: Node) => {
        node.data.questionId = node.data.questionId || getNewNodeId(this.editor)

        const input = new Input('in', '', NodeSocket, true)
        const questionId = new QuestionIdControl(this.editor, 'questionId', node, 'Question ID')
        const questionText = new TextControl(this.editor, 'questionText', node)
        const checkbox = new CheckboxControl(this.editor, 'checkbox', node)
        const output = new Output('out', '', NodeSocket, false)

        node.addInput(input).addControl(questionId).addControl(questionText).addControl(checkbox).addOutput(output)
    }

    worker = (node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs) => {}
}

export default TellComponent
