import { Control, Node, NodeEditor } from 'rete'

export interface CheckboxControlProps {
    value: boolean
    onChange: (value: boolean) => void
}

class CheckboxControl extends Control {
    key: string
    render: string
    emitter: NodeEditor | null
    component: (props: CheckboxControlProps) => JSX.Element
    props: CheckboxControlProps

    static component = ({ value, onChange }: CheckboxControlProps) => {
        return (
            <div className="checkbox-control">
                <input
                    type="checkbox"
                    value="required"
                    className="checkbox-control-input"
                    checked={value}
                    ref={(ref) => {
                        ref && ref.addEventListener('pointerdown', (e) => e.stopPropagation())
                    }}
                    onChange={(e) => onChange(e.target.checked)}
                />
                <label className="checkbox-control-label">Answer is required</label>
            </div>
        )
    }

    constructor(emitter: NodeEditor | null, key: string, node: Node) {
        super(key)
        this.render = 'react'
        this.emitter = emitter
        this.key = key
        this.component = CheckboxControl.component

        const initial = typeof node.data[key] === 'undefined' ? true : (node.data[key] as boolean)

        node.data[key] = initial

        this.props = {
            value: initial,
            onChange: (v) => {
                // @ts-ignore
                if (emitter.preview) return
                this.setValue(v)
            },
        }
    }

    setValue(val: boolean) {
        this.props.value = val
        this.putData(this.key, val)
        // @ts-ignore
        this.update()
    }
}

export default CheckboxControl
