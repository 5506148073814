import React from 'react'
import firebase from 'firebase'
import { ThemeProvider } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'

import { muiTheme, snackTheme } from './themes'
import { AuthenticationProvider } from './components/context/AuthenticationProvider'
import { DataProvider } from './components/context/DataProvider'
import Router from './components/common/Router'
import { ModesProvider } from './components/context/ModesProvider'

const firebaseConfig = {
    apiKey: 'AIzaSyB-2bI0ZVeYOE1LQoMLG06M6-1ptrhy7lc',
    authDomain: 'sven-f3b7e.firebaseapp.com',
    databaseURL: 'https://sven-f3b7e.firebaseio.com',
    projectId: 'sven-f3b7e',
    storageBucket: 'sven-f3b7e.appspot.com',
    messagingSenderId: '775395213080',
    appId: '1:775395213080:web:d53d49a5f27f4a10e3e86d',
    measurementId: 'G-FTZVB4GXQV',
}

firebase.initializeApp(firebaseConfig)

const useStyles = makeStyles((theme) => {
    return {
        ...snackTheme,
    }
})

const App: React.FC = (props) => {
    const classes = useStyles()

    return (
        <ThemeProvider theme={muiTheme}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantInfo: classes.info,
                    variantWarning: classes.warning,
                }}
            >
                <AuthenticationProvider>
                    <DataProvider>
                        <ModesProvider>
                            <Router />
                        </ModesProvider>
                    </DataProvider>
                </AuthenticationProvider>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default App
