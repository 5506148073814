import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Layout from '../common/Layout'
import ErrorDisplay from '../common/ErrorDisplay'
import ProgressIndicator from '../common/ProgressIndicator'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 600,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    paragraph: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(4),
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(4),
    },
    warningButton: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
    },
    dangerButton: {
        backgroundColor: theme.palette.error.main,
    },
}))

const OrganisationCreatePage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const { organisationId } = useParams<{ organisationId: string }>()
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [organisation, setOrganisation] = useState<any>(null)
    const [name, setName] = useState('')

    useEffect(
        () => {
            loadOrganisation()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisationId]
    )

    const handleNameChange = (event: any) => {
        setName(event.target.value)
    }

    const loadOrganisation = async () => {
        if (organisationId) {
            const db = firebase.firestore()
            setLoading(true)
            db.collection('organisations')
                .doc(organisationId)
                .get()
                .then((document) => {
                    if (document.exists) {
                        const data = document.data()
                        setOrganisation({ id: organisationId, ...data })
                        setName(data!.name)
                        setError(null)
                        setLoading(false)
                    } else {
                        setOrganisation(null)
                        setError(`Organisation with ID = '${organisationId}' does not exist.`)
                        setLoading(false)
                    }
                })
                .catch((error) => {
                    setOrganisation(null)
                    setError(JSON.stringify(error))
                    setLoading(false)
                })
        }
    }

    const handleUpdateOrganisation = (event: any) => {
        if (name) {
            const db = firebase.firestore()
            const newOrganisation = {
                name,
                archived: organisation.archived ? organisation.archived : false,
            }
            setUpdating(true)
            db.collection('organisations')
                .doc(organisationId)
                .set(newOrganisation, { merge: true })
                .then((_) => {
                    setUpdating(false)
                    history.push('/organisations')
                })
                .catch(function (error) {
                    setUpdating(false)
                })
        } else {
            alert('Please fill in all fields to create an organisation')
        }
    }

    const handleDeleteOrganisation = () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm('Do you really want to delete this organisation?')

        if (confirmation) {
            const db = firebase.firestore()
            setUpdating(true)
            db.collection('organisations')
                .doc(organisationId)
                .delete()
                .then(() => {
                    setUpdating(false)
                    history.push('/organisations')
                })
                .catch((_) => {
                    setUpdating(false)
                })
        }
    }

    const handleArchiveOrganisation = () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm(
            'Do you really want to archive this organisation?\n\nIt will no longer appear in the conversations list'
        )

        if (confirmation) {
            const db = firebase.firestore()
            setUpdating(true)
            db.collection('organisations')
                .doc(organisationId)
                .set({ ...organisation, archived: true }, { merge: true })
                .then(() => {
                    setUpdating(false)
                    history.push('/organisations')
                })
                .catch((_) => {
                    setUpdating(false)
                })
        }
    }

    const renderContent = () => {
        if (loading) return <ProgressIndicator text="Loading organisation..." />
        if (error) return <ErrorDisplay text={error} />
        if (organisation)
            return (
                <div className={classes.wrapper}>
                    <Typography variant="h2" className={classes.header}>
                        Update organisation
                    </Typography>
                    <div className={classes.paragraph}>
                        <Typography variant="body1">Make sure all fields are entered correctly</Typography>
                        <div>
                            <Button
                                className={classes.warningButton}
                                variant="outlined"
                                onClick={handleArchiveOrganisation}
                                disabled={updating}
                            >
                                Archive
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleDeleteOrganisation}
                                disabled={updating}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.form}>
                        <TextField
                            id="name"
                            name="name"
                            label="Name"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={name}
                            onChange={handleNameChange}
                        />
                        <div className={classes.buttons}>
                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() => history.goBack()}
                                disabled={updating}
                            >
                                Back
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleUpdateOrganisation}
                                disabled={updating}
                            >
                                Update organisation
                            </Button>
                        </div>
                    </div>
                </div>
            )
        return null
    }

    return (
        <Layout>
            <div className={classes.container}>{renderContent()}</div>
        </Layout>
    )
}

export default OrganisationCreatePage
