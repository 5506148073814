import { useContext, useEffect, useState } from 'react'
import firebase from 'firebase'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import Layout from '../common/Layout'
import { languages } from '../../utils/options'
import { Organisation } from '../../models/Organisation'
import { Conversation } from '../../models/Conversation'
import ProgressIndicator from '../common/ProgressIndicator'
import ErrorDisplay from '../common/ErrorDisplay'
import { DataContext } from '../context/DataProvider'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 600,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    utilities: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(4),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(4),
    },
    warningButton: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
    },
    dangerButton: {
        backgroundColor: theme.palette.error.main,
    },
}))

const ConversationEditPage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const { organisations } = useContext(DataContext)
    const { conversationId } = useParams<{ conversationId: string }>()
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [conversation, setConversation] = useState<any>(null)
    const [name, setName] = useState('')
    const [topic, setTopic] = useState('')
    const [organisation, setOrganisation] = useState('')
    const [language, setLanguage] = useState('en')
    const [status, setStatus] = useState('open')

    const handleNameChange = (event: any) => {
        setName(event.target.value)
    }

    const handleTopicChange = (event: any) => {
        setTopic(event.target.value)
    }

    const handleOrganisationChange = (event: any) => {
        setOrganisation(event.target.value)
    }

    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value)
    }

    const handleStatusChange = (event: any) => {
        setStatus(event.target.value)
    }

    const loadConversation = async () => {
        if (conversationId) {
            const db = firebase.firestore()
            setLoading(true)
            db.collection('conversations')
                .doc(conversationId)
                .get()
                .then((document) => {
                    if (document.exists) {
                        const data = document.data() as Conversation
                        setConversation({ ...data, id: conversationId })
                        setName(data.name)
                        setTopic(data.topic || data.topicId!)
                        setOrganisation(data.organisation)
                        setLanguage(data.language)
                        setStatus(data.status || 'open')
                        setError(null)
                        setLoading(false)
                    } else {
                        setConversation(null)
                        setError(`Conversation with ID = '${conversationId}' does not exist.`)
                        setLoading(false)
                    }
                })
                .catch((error) => {
                    setConversation(null)
                    setError(JSON.stringify(error))
                    setLoading(false)
                })
        }
    }

    const handleUpdateConversation = () => {
        if (name && topic && language && organisation) {
            const db = firebase.firestore()
            const newConversation = {
                name,
                topic,
                organisation,
                language,
                data: conversation.data || JSON.stringify({ id: 'demo@0.1.0', nodes: {} }),
                topicId: topic,
                archived: conversation.archived ? conversation.archived : false,
                status,
            }
            setUpdating(true)
            db.collection('conversations')
                .doc(conversationId)
                .set(newConversation, { merge: true })
                .then((_) => {
                    setUpdating(false)
                    enqueueSnackbar('Updated successfully', {
                        variant: 'success',
                    })
                })
                .catch((_) => {
                    enqueueSnackbar('Something went wrong', {
                        variant: 'error',
                    })
                    setUpdating(false)
                })
        } else {
            alert('Please fill in all fields to update the conversation')
        }
    }

    const handleGoToCanvas = () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm('Do you want to first save the conversation data?\n\n')

        if (confirmation) {
            if (name && topic && language && organisation) {
                const db = firebase.firestore()
                const newConversation = {
                    name,
                    topic,
                    organisation,
                    language,
                    data: conversation.data || JSON.stringify({ id: 'demo@0.1.0', nodes: {} }),
                    topicId: topic,
                    archived: conversation.archived ? conversation.archived : false,
                    status,
                }
                setUpdating(true)
                db.collection('conversations')
                    .doc(conversationId)
                    .set(newConversation, { merge: true })
                    .then((_) => {
                        setUpdating(false)
                        enqueueSnackbar('Updated successfully', {
                            variant: 'success',
                        })
                        history.push(`/conversations/edit/${conversation.id}`)
                    })
                    .catch((_) => {
                        enqueueSnackbar('Something went wrong', {
                            variant: 'error',
                        })
                        setUpdating(false)
                    })
            } else {
                alert('Please fill in all fields to update the conversation')
            }
        } else {
            history.push(`/conversations/edit/${conversation.id}`)
        }
    }

    const handleCloneConversation = () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm('Do you really want to clone this conversation?\n\n')

        if (confirmation) {
            if (conversation) {
                const db = firebase.firestore()
                setUpdating(true)
                const newConversation = {
                    name: `CLONE - ${conversation.name}`,
                    topic: conversation.topic || conversation.topicId,
                    organisation: conversation.organisation || '',
                    language: conversation.language,
                    data: conversation.data,
                    topicId: conversation.topic || conversation.topicId,
                    status: conversation.status || 'open',
                    archived: false,
                }
                db.collection('conversations')
                    .add(newConversation)
                    .then(function (docRef) {
                        setUpdating(false)
                        enqueueSnackbar('Cloned successfully', {
                            variant: 'success',
                        })
                        history.push(`/conversations/${docRef.id}`)
                    })
                    .catch((_) => {
                        enqueueSnackbar('Something went wrong', {
                            variant: 'error',
                        })
                        setUpdating(false)
                    })
            }
        }
    }

    const handleDeleteConversation = () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm('Do you really want to delete this conversation?')

        if (confirmation) {
            const db = firebase.firestore()
            setUpdating(true)
            db.collection('conversations')
                .doc(conversationId)
                .delete()
                .then(() => {
                    setUpdating(false)
                    history.push('/conversations')
                })
                .catch((_) => {
                    setUpdating(false)
                })
        }
    }

    const handleArchiveConversation = () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm(
            'Do you really want to archive this conversation?\n\nIt will no longer appear in the conversations list'
        )

        if (confirmation) {
            const db = firebase.firestore()
            setUpdating(true)
            db.collection('conversations')
                .doc(conversationId)
                .set({ ...conversation, archived: true }, { merge: true })
                .then(() => {
                    setUpdating(false)
                    history.push('/conversations')
                })
                .catch((_) => {
                    setUpdating(false)
                })
        }
    }

    const renderContent = () => {
        if (loading) return <ProgressIndicator text="Loading conversation..." />
        if (error) return <ErrorDisplay text={error} />
        if (conversation)
            return (
                <div className={classes.wrapper}>
                    <Typography variant="h2" className={classes.header}>
                        Conversation details
                    </Typography>
                    <div className={classes.utilities}>
                        <div>
                            <Button
                                color="default"
                                variant="outlined"
                                onClick={handleCloneConversation}
                                disabled={updating}
                            >
                                Clone
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                className={classes.warningButton}
                                variant="outlined"
                                onClick={handleArchiveConversation}
                                disabled={updating}
                            >
                                Archive
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={handleDeleteConversation}
                                disabled={updating}
                            >
                                Delete
                            </Button>
                        </div>
                        <div>
                            <Button color="default" variant="outlined" onClick={handleGoToCanvas} disabled={updating}>
                                Go to Canvas
                            </Button>
                        </div>
                    </div>
                    <div className={classes.form}>
                        <TextField
                            id="name"
                            name="name"
                            label="Name"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={name}
                            onChange={handleNameChange}
                        />
                        <TextField
                            id="topic"
                            name="topic"
                            label="Topic"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={topic}
                            onChange={handleTopicChange}
                        />
                        <TextField
                            select
                            id="organisation"
                            name="organisation"
                            label="Organisation"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={organisation}
                            onChange={handleOrganisationChange}
                        >
                            {organisations.map((option: Organisation) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            id="language"
                            name="language"
                            label="Language"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={language}
                            onChange={handleLanguageChange}
                        >
                            {languages.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            id="status"
                            name="status"
                            label="Status"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={status}
                            onChange={handleStatusChange}
                        >
                            <MenuItem value={'open'}>Open</MenuItem>
                            <MenuItem value={'closed'}>Closed</MenuItem>
                            <MenuItem value={'locked'}>Locked</MenuItem>
                        </TextField>
                        <div className={classes.buttons}>
                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() => history.push('/conversations')}
                                disabled={updating}
                            >
                                Back
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleUpdateConversation}
                                disabled={updating}
                            >
                                Update conversation
                            </Button>
                        </div>
                    </div>
                </div>
            )
        return null
    }

    useEffect(
        () => {
            loadConversation()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [conversationId]
    )

    return (
        <Layout>
            <div className={classes.container}>{renderContent()}</div>
        </Layout>
    )
}

export default ConversationEditPage
