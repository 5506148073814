import { Component, Input, Node } from 'rete'
import { NodeData, WorkerInputs, WorkerOutputs } from 'rete/types/core/data'

import EndNode from '../nodes/EndNode'
import { getNewNodeId } from '../../../utils/nodes'
import NodeSocket from '../sockets/NodeSocket'
import QuestionIdControl from '../controls/QuestionIdControl'
import TextControl from '../controls/TextControl'

class EndComponent extends Component {
    constructor() {
        super('END')
        // @ts-ignore
        this.data.component = EndNode
    }

    builder = async (node: Node) => {
        node.data.questionId = node.data.questionId || getNewNodeId(this.editor)

        var input = new Input('in', '', NodeSocket, true)
        const questionId = new QuestionIdControl(this.editor, 'questionId', node, 'Question ID')
        const questionText = new TextControl(this.editor, 'questionText', node)

        node.addInput(input).addControl(questionId).addControl(questionText)
    }

    worker = (node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs) => {}
}

export default EndComponent
