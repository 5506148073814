import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import ProtectedRoute from '../common/ProtectedRoute'
import HomePage from '../pages/HomePage'
import LoginPage from '../pages/LoginPage'
import ConversationCreatePage from '../pages/ConversationCreatePage'
import ClientConversationCreatePage from '../pages/ClientConversationCreatePage'
import ConversationWorkPage from '../pages/ConversationWorkPage'
import ConversationPreviewPage from '../pages/ConversationPreviewPage'
import ConversationEditPage from '../pages/ConversationEditPage'
import ConversationListPage from '../pages/ConversationListPage'
import OrganisationCreatePage from '../pages/OrganisationCreatePage'
import OrganisationEditPage from '../pages/OrganisationEditPage'
import OrganisationListPage from '../pages/OrganisationListPage'

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/conversation/edit/:conversationId" component={ConversationWorkPage} />
                <Route path="/conversations/edit/:conversationId" component={ConversationWorkPage} />
                <Route path="/conversations/preview/:conversationId" component={ConversationPreviewPage} />
                <Route path="/conversations/client-create/:organisationId" component={ClientConversationCreatePage} />
                <ProtectedRoute path="/conversations/create" component={ConversationCreatePage} />
                <ProtectedRoute path="/conversations/:conversationId" component={ConversationEditPage} />
                <ProtectedRoute path="/conversations" component={ConversationListPage} />
                <ProtectedRoute path="/organisations/create" component={OrganisationCreatePage} />
                <ProtectedRoute path="/organisations/:organisationId" component={OrganisationEditPage} />
                <ProtectedRoute path="/organisations" component={OrganisationListPage} />
            </Switch>
        </BrowserRouter>
    )
}

export default Router
