import { Control, Node, NodeEditor } from 'rete'

interface NumberControlProps {
    label: string
    value: number
    onChange: (value: number) => void
}
class NumberControl extends Control {
    key: string
    render: string
    emitter: NodeEditor | null
    component: (props: NumberControlProps) => JSX.Element
    props: NumberControlProps

    static component = ({ label, value, onChange }: NumberControlProps) => (
        <div className="number-control">
            <label className="number-control-label">{`${label}`}</label>
            <input
                type="number"
                className="number-control-input"
                value={value}
                ref={(ref) => {
                    ref && ref.addEventListener('pointerdown', (e) => e.stopPropagation())
                }}
                onChange={(e) => onChange(+e.target.value)}
            />
        </div>
    )

    constructor(emitter: NodeEditor | null, key: string, node: Node, label: string) {
        super(key)
        this.render = 'react'
        this.emitter = emitter
        this.key = key
        this.component = NumberControl.component

        const initial = (node.data[key] as number) || 0

        node.data[key] = initial

        this.props = {
            value: initial,
            label,
            onChange: (v) => {
                //@ts-ignore
                if (emitter.preview) return
                this.setValue(v)
            },
        }
    }

    setValue(val: number) {
        this.props.value = val
        this.putData(this.key, val)
        //@ts-ignore
        this.update()
    }
}

export default NumberControl
