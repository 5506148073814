import React, { useEffect, useState } from 'react'

type ContextProps = {
    focusMode: boolean
    diagnosisMode: boolean
    setFocusMode: (value: boolean) => void
    setDiagnosisMode: (value: boolean) => void
}

const defaultContext = {
    focusMode: false,
    diagnosisMode: false,
    setFocusMode: () => {},
    setDiagnosisMode: () => {},
}

export const ModesContext = React.createContext<ContextProps>(defaultContext)

export const ModesProvider = ({ children }: any) => {
    const [focus, setFocus] = useState<boolean>(false)
    const [diagnosis, setDiagnosis] = useState<boolean>(false)

    const setFocusMode = (value: boolean): void => {
        localStorage.setItem('focusMode', JSON.stringify(value))
        setFocus(value)
    }

    const setDiagnosisMode = (value: boolean): void => {
        localStorage.setItem('diagnosisMode', JSON.stringify(value))
        setDiagnosis(value)
    }

    useEffect(() => {
        setFocus(JSON.parse(localStorage.getItem('focusMode') || 'false'))
        setDiagnosis(
            JSON.parse(localStorage.getItem('diagnosisMode') || 'false')
        )
    }, [])

    return (
        <ModesContext.Provider
            value={{
                focusMode: focus,
                diagnosisMode: diagnosis,
                setFocusMode,
                setDiagnosisMode,
            }}
        >
            {children}
        </ModesContext.Provider>
    )
}
