import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import firebase from 'firebase'
import { makeStyles } from '@material-ui/core/styles'
import { default as MaterialAppBar } from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import ForumIcon from '@material-ui/icons/Forum'
import BusinessIcon from '@material-ui/icons/Business'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SpeedIcon from '@material-ui/icons/Speed'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { AuthenticationContext } from '../context/AuthenticationProvider'
import ArnoldLogo from './ArnoldLogo'
import { ModesContext } from '../context/ModesProvider'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        borderBottom: '1px solid #EDEDED',
        height: 92,
    },
    toobarDropdownButton: {
        paddingRight: '10px',
    },
}))

const AppBar: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const { authenticated } = useContext(AuthenticationContext)
    const { diagnosisMode, setDiagnosisMode } = useContext(ModesContext)

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleItemClick = (item: string) => {
        setAnchorEl(null)
        history.push(`/${item}`)
    }

    const handleDiagnosisMode = () => {
        setAnchorEl(null)
        const disablingMessage =
            'Do you really want to continue?\n\nPlease make sure your conversation is correct and saved by clicking DIAG & SAVE button. This will reload the conversation and enable automatic conversation saving.\n\n!!! - You can permanently corrupt your conversation when it contains duplicate question Ids. Please make use of DIAG & SAVE button!'
        const enablingMessage =
            'Please make sure your conversation is saved before you continue. This step will reload the conversation and disable automatic conversation saving.\n\nDo you want to proceed?'
        const message = diagnosisMode ? disablingMessage : enablingMessage
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm(message)

        if (confirmation) {
            setDiagnosisMode(!diagnosisMode)
            window.location.reload()
        }
    }

    const handleLogout = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                history.push('/')
            })
            .catch((error) => {
                alert(error.message)
            })
    }

    return (
        <MaterialAppBar position="fixed" elevation={0} color="default">
            <Toolbar className={classes.toolbar}>
                <Link to={'/'}>
                    <ArnoldLogo />
                </Link>
                {authenticated ? (
                    <>
                        <Button
                            aria-haspopup="true"
                            onClick={handleMenuOpen}
                            variant="outlined"
                            color="primary"
                            className={classes.toobarDropdownButton}
                        >
                            <MenuIcon />
                            &nbsp;&nbsp;Admin menu&nbsp;
                            <ArrowDropDownIcon />
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => handleItemClick('conversations')}>
                                <ForumIcon />
                                &nbsp;&nbsp;Manage Conversations
                            </MenuItem>
                            <MenuItem onClick={() => handleItemClick('organisations')}>
                                <BusinessIcon />
                                &nbsp;&nbsp;Manage Organisations
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleDiagnosisMode}>
                                <SpeedIcon />
                                &nbsp;&nbsp;
                                {diagnosisMode ? 'Disable diag mode' : 'Enable diag mode'}
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleLogout}>
                                <ExitToAppIcon />
                                &nbsp;&nbsp;Logout
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <Button variant="outlined" component={Link} to={'/login'}>
                        Login
                    </Button>
                )}
            </Toolbar>
        </MaterialAppBar>
    )
}

export default AppBar
