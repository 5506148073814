import React from 'react'
import { useParams } from 'react-router-dom'

import Layout from '../common/Layout'
import ConversationLoader from '../common/ConversationLoader'

const ConversationPreviewPage: React.FC = () => {
    const { conversationId } = useParams<{ conversationId: string }>()

    return (
        <Layout>
            <ConversationLoader conversationId={conversationId.split('').reverse().join('')} preview={true} />
        </Layout>
    )
}

export default ConversationPreviewPage
