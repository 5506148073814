import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        marginTop: theme.spacing(2),
    },
}))

type Props = {
    text?: string
}

const ProgressIndicator: React.FC<Props> = ({ text }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <CircularProgress />
            {text && (
                <Typography variant="body2" className={classes.text}>
                    {text}
                </Typography>
            )}
        </div>
    )
}

export default ProgressIndicator
