import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AuthenticationContext } from '../context/AuthenticationProvider'

//@ts-ignore
const ProtectedRoute = ({ component: RouteComponent, ...rest }) => {
    const { authenticated, loadingAuthState } = useContext(
        AuthenticationContext
    )

    if (loadingAuthState) {
        return <pre>Authenticating...</pre>
    }

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                authenticated ? (
                    <RouteComponent {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { prevPath: rest.path },
                        }}
                    />
                )
            }
        />
    )
}
export default ProtectedRoute
