import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Layout from '../common/Layout'
import { AuthenticationContext } from '../context/AuthenticationProvider'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 600,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    paragraph: {
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(4),
    },
}))

const HomePage: React.FC = () => {
    const { authenticated } = useContext(AuthenticationContext)
    const classes = useStyles()

    return (
        <Layout>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <Typography variant="h2" className={classes.header}>
                        Welcome to Sven!
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Sven is a visual conversation editor used for creating
                        and editing conversation for Arnold - the conversation
                        robot.
                    </Typography>
                    {authenticated ? (
                        <>
                            <Typography
                                variant="body2"
                                className={classes.paragraph}
                            >
                                <strong>
                                    Great ... you have the right key, go ahead
                                    and create new conversation.
                                </strong>
                            </Typography>
                            <Button
                                className={classes.button}
                                variant="outlined"
                                color="primary"
                                component={Link}
                                to={'/conversations'}
                            >
                                See all conversations
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography
                                variant="body2"
                                className={classes.paragraph}
                            >
                                <strong>
                                    Oh shoot ... it looks like your link is not
                                    longer valid.
                                </strong>
                            </Typography>
                            <Typography variant="body2">
                                Please contact your administrator if you think
                                this is an error.
                            </Typography>
                        </>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default HomePage
