import React, { useState, useContext } from 'react'
import firebase from 'firebase'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { default as MaterialToolbar } from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { useSnackbar } from 'notistack'
import InfoIcon from '@material-ui/icons/Info'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import SettingsIcon from '@material-ui/icons/Settings'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AddBoxIcon from '@material-ui/icons/AddBox'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import PublishIcon from '@material-ui/icons/Publish'
import GetAppIcon from '@material-ui/icons/GetApp'
import BuildIcon from '@material-ui/icons/Build'
import SaveIcon from '@material-ui/icons/Save'
import SpeedIcon from '@material-ui/icons/Speed'
import TranslateIcon from '@material-ui/icons/Translate'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PublicIcon from '@material-ui/icons/Public'
import ClosedIcon from '@material-ui/icons/Cancel'
import LockedIcon from '@material-ui/icons/Lock'
import { blue, green, grey } from '@material-ui/core/colors'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { readImportFile, writeImportFile } from '../../utils/csv'
import { languages } from '../../utils/options'
import { ConversationContext } from '../context/ConversationContext'
import { AuthenticationContext } from '../context/AuthenticationProvider'
import {
    Menu,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Chip,
} from '@material-ui/core'
import { ModesContext } from '../context/ModesProvider'
import { fixEditorNodes, verifyEditorNodes } from '../../utils/nodes'
import { readLanguageFile, writeLanguageFile } from '../../utils/languages'
import { Conversation } from '../../models/Conversation'
import { Validation } from '../../models/Validation'
import { Typography } from '@material-ui/core'
import ProgressIndicator from './ProgressIndicator'
import { Organisation } from 'models/Organisation'
import { DataContext } from 'components/context/DataProvider'
import {
    numberOfStartNodesValidator,
    numberOfEndNodesValidator,
    checkMinMaxMultiselectNodesValidator,
    checkMinMaxPersonSelectNodesValidator,
    checkSelectOutConnectionsValidator,
    checkMultiSelectOutConnectionsValidator,
    checkQuestionTextEmptyValidator,
    checkMultiSelectMinAndNumberOfOptionsValidator,
    checkMultiSelectMaxAndNumberOfOptionsValidator,
    checkSelectOptionsTextEmptyValidator,
    checkMultiselectOptionsTextEmptyValidator,
} from 'utils/validations'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    toobarDropdownButton: {
        paddingRight: '10px',
    },
    toolbarLeftSideButtonItem: {
        marginRight: theme.spacing(3),
    },
    toolbarRightSideButtonItem: {
        marginLeft: theme.spacing(3),
    },
    spacer: {
        display: 'flex',
        flexGrow: 1,
    },
    separator: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '1px',
        backgroundColor: theme.palette.grey[200],
    },
    dialogTextItem: {
        marginBottom: theme.spacing(3),
    },
    thisAvatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    newAvatar: {
        backgroundColor: green[100],
        color: green[600],
    },
    avatar: {
        backgroundColor: grey[100],
        color: grey[600],
    },
    statusIcon: {
        color: grey[500],
    },
    accordionSummary: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexGrow: 1,
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    accordionDetailsRow: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(2),
    },
    meta: {
        position: 'relative',
        top: '2px',
        color: grey[500],
    },
    label: {
        fontWeight: 'bold',
        minWidth: '180px',
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}))

type ToolBarProps = {
    preview: boolean
}

const ToolBar: React.FC<ToolBarProps> = ({ preview }) => {
    const classes = useStyles()
    const { authenticated } = useContext(AuthenticationContext)
    const { diagnosisMode } = useContext(ModesContext)
    const { conversation } = useContext(ConversationContext)
    const { organisations } = useContext(DataContext)
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()

    const [manageEl, setManageEl] = React.useState(null)
    const [diagEl, setDiagEl] = React.useState(null)

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(conversation.name)
    const [displayName, setDisplayName] = useState(conversation.name)
    const [topic, setTopic] = useState(conversation.topic || conversation.topicId)
    const [displayTopic, setDisplayTopic] = useState(conversation.topic || conversation.topicId)
    const [organisation, setOrganisation] = useState(conversation.organisation)
    const [language, setLanguage] = useState(conversation.language || 'en')
    const [status, setStatus] = useState(conversation.status || 'open')

    const [infoDialogOpen, setInfoDialogOpen] = useState(false)
    const [conversationsDialogOpen, setConversationsDialogOpen] = useState(false)
    const [validationsDialogOpen, setValidationsDialogOpen] = useState(false)

    const [otherConversations, setOtherConversations] = useState<Conversation[]>([])
    const [otherConversationsLoading, setOtehrConversationsLoading] = useState(false)

    const [validations, setValidations] = useState<Validation[]>([])

    const getLatestConversationData = (conversationId: string) => {
        const db = firebase.firestore()

        return db.collection('conversations').doc(conversationId).get()
    }

    const handleManageOpen = (event: any) => {
        setManageEl(event.currentTarget)
    }

    const handleManageClose = () => {
        setManageEl(null)
    }

    const handleDiagOpen = (event: any) => {
        setDiagEl(event.currentTarget)
    }

    const handleDiagClose = () => {
        setDiagEl(null)
    }

    const handleOpenInfoDialog = () => {
        if (conversation) {
            setInfoDialogOpen(true)
        }
    }

    const handleCloseInfoDialog = () => {
        setInfoDialogOpen(false)
    }

    const handleOpenConversationsDialog = () => {
        if (conversation) {
            setOtehrConversationsLoading(true)
            setConversationsDialogOpen(true)

            const db = firebase.firestore()

            const colRef = db
                .collection('conversations')
                .where('organisation', '==', conversation.organisation || '')
                .where('archived', '==', false)

            colRef
                .get()
                .then((snapshot) => {
                    const loadedConversations: Conversation[] = []
                    snapshot.forEach((doc) => {
                        loadedConversations.push({ ...doc.data(), id: doc.id } as Conversation)
                    })
                    setOtherConversations(loadedConversations)
                    setOtehrConversationsLoading(false)
                })
                .catch((_) => {
                    enqueueSnackbar('Something went wrong when loading other conversations', {
                        variant: 'error',
                    })
                    setOtehrConversationsLoading(false)
                })
        }
    }

    const handleCloseConversationsDialog = () => {
        setConversationsDialogOpen(false)
    }

    const handleCloseValidationsDialog = () => {
        setValidationsDialogOpen(false)
    }

    const handleOtherConversationClick = (conversation: Conversation) => {
        if (preview) {
            history.push(`/conversations/preview/${conversation.id.split('').reverse().join('')}`)
        } else {
            history.push(`/conversations/edit/${conversation.id}`)
        }
    }

    const handleImportConversation = async (event: any) => {
        setManageEl(null)
        const { __editor: editor, __engine: engine } = window
        if (editor && engine) {
            readImportFile(editor, conversation)
        }
    }

    const handleExportConversation = async (event: any) => {
        const validators = [
            numberOfStartNodesValidator,
            numberOfEndNodesValidator,
            checkMinMaxMultiselectNodesValidator,
            checkMinMaxPersonSelectNodesValidator,
            checkSelectOutConnectionsValidator,
            checkMultiSelectOutConnectionsValidator,
            checkQuestionTextEmptyValidator,
            checkMultiSelectMinAndNumberOfOptionsValidator,
            checkMultiSelectMaxAndNumberOfOptionsValidator,
            checkSelectOptionsTextEmptyValidator,
            checkMultiselectOptionsTextEmptyValidator,
        ]
        const allValidationResults: Validation[] = []

        setManageEl(null)
        const { __editor: editor, __engine: engine } = window
        if (editor && engine) {
            validators.forEach((validation) => {
                const validationResult = validation(editor)
                if (validationResult) allValidationResults.push(validationResult)
            })

            if (allValidationResults.length > 0) {
                setValidations(allValidationResults)
                setValidationsDialogOpen(true)
                return
            }

            await engine.abort()
            const editorData = await editor.toJSON()
            localStorage.setItem('editorData', JSON.stringify(editorData))
            getLatestConversationData(conversation.id).then((document) => {
                const data = document.data()
                writeImportFile(editor, { id: conversation.id, ...data })
            })
        }
    }

    const handleLoadLabels = async (event: any) => {
        setManageEl(null)
        const { __editor: editor, __engine: engine } = window
        if (editor && engine) {
            readLanguageFile(editor, conversation, handleChangeLanguage)
        }
    }

    const handleExportLabels = async (event: any) => {
        setManageEl(null)
        const { __editor: editor, __engine: engine } = window
        if (editor && engine) {
            await engine.abort()
            const editorData = await editor.toJSON()
            localStorage.setItem('editorData', JSON.stringify(editorData))
            getLatestConversationData(conversation.id).then((document) => {
                const data = document.data()
                writeLanguageFile(editor, { id: conversation.id, ...data })
            })
        }
    }

    const handleChangeLanguage = (lang: string) => {
        setLanguage(lang)
    }

    const handleCloneConversation = async (event: any) => {
        setManageEl(null)
        const { __editor: editor, __engine: engine } = window

        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm(
            'Do you really want to continue?\n\nPlease make sure you saved the current conversation. Continuing with conversation clone will redirect you to new conversation and unsaved data on current conversation might be lost.'
        )

        if (confirmation) {
            if (editor && engine) {
                if (conversation) {
                    const db = firebase.firestore()
                    setLoading(true)
                    const editorData = await editor.toJSON()
                    const newConversation = {
                        name: `CLONE - ${conversation.name}`,
                        topic: conversation.topic || conversation.topicId,
                        organisation: conversation.organisation || '',
                        language: conversation.language,
                        data: JSON.stringify(editorData),
                        topicId: conversation.topic || conversation.topicId,
                        status: conversation.status || 'open',
                        archived: false,
                    }
                    db.collection('conversations')
                        .add(newConversation)
                        .then(function (docRef) {
                            setLoading(false)
                            enqueueSnackbar('Cloned successfully', {
                                variant: 'success',
                            })
                            history.push(`/conversations/edit/${docRef.id}`)
                        })
                        .catch((_) => {
                            enqueueSnackbar('Something went wrong', {
                                variant: 'error',
                            })
                            setLoading(false)
                        })
                } else {
                    alert('Please fill in all fields to create a conversation')
                }
            }
        }
    }

    const handleCreateNewConversation = async (event: any) => {
        setManageEl(null)
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm(
            'Do you really want to continue?\n\nPlease make sure you saved the current conversation. Continuing with new conversation creation will redirect you to new page and unsaved data on current conversation might be lost.'
        )

        if (confirmation) {
            let url = '/conversations/create'
            history.push(url)
        }
    }

    const handleCreateNewClientConversation = async (event: any) => {
        let url = `/conversations/client-create/${conversation.organisation}`
        history.push(url)
    }

    const handleSaveConversation = async (event: any) => {
        setManageEl(null)
        const { __editor: editor, __engine: engine } = window
        if (editor && engine) {
            const db = firebase.firestore()
            setLoading(true)
            const editorData = await editor.toJSON()
            const newConversationData: Partial<Conversation> = {
                data: JSON.stringify(editorData),
            }
            if (language) {
                newConversationData.language = language
            }
            db.collection('conversations')
                .doc(conversation.id)
                .set(newConversationData, {
                    merge: true,
                })
                .then(() => {
                    enqueueSnackbar('Saved successfully', {
                        variant: 'success',
                    })
                    setLoading(false)
                })
                .catch((_) => {
                    enqueueSnackbar('Something went wrong', {
                        variant: 'error',
                    })
                    setLoading(false)
                })
        }
    }

    const handleDiagnoseConversation = async (event: any) => {
        setDiagEl(null)
        const { __editor: editor, __engine: engine } = window
        if (editor && engine) {
            const result = verifyEditorNodes(editor)
            if (!result) {
                alert(
                    'Seems that your conversation contains errors.\n\nPlease find additional logging and possible hints for fixes in browser console.'
                )
            } else {
                alert(
                    'Seems your conversation is without errors.\n\nPlease find additional logging in browser console.'
                )
            }
        }
    }

    const handleFixConversation = async (event: any) => {
        setDiagEl(null)
        const { __editor: editor, __engine: engine } = window

        if (editor && engine) {
            fixEditorNodes(editor)
            alert('Nodes fixed.\n\nPlease click on DIAG & SAVE button in DIAG menu to update editor with latest data.')
        }
    }

    const handleDiagAndSaveConversation = async (event: any) => {
        setDiagEl(null)
        const { __editor: editor, __engine: engine } = window
        if (editor && engine) {
            const result = verifyEditorNodes(editor)
            if (result) {
                const db = firebase.firestore()
                setLoading(true)
                const editorData = await editor.toJSON()
                db.collection('conversations')
                    .doc(conversation.id)
                    .set(
                        {
                            data: JSON.stringify(editorData),
                        },
                        {
                            merge: true,
                        }
                    )
                    .then(() => {
                        enqueueSnackbar('Saved successfully', {
                            variant: 'success',
                        })
                        setLoading(false)
                    })
                    .catch((_) => {
                        enqueueSnackbar('Something went wrong', {
                            variant: 'error',
                        })
                        setLoading(false)
                    })
            } else {
                alert(
                    'Seems that your conversation contains errors. Please find additional logging and possible hints for fixes in browser console.'
                )
            }
        }
    }

    const handleClearConversation = async (event: any) => {
        const { __editor: editor, __engine: engine } = window
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm('Do you really want to clear the canvas and delete the conversation flow?')
        if (confirmation) {
            editor.clear()
            await engine.abort()
            const editorData = await editor.toJSON()
            localStorage.setItem('editorData', JSON.stringify(editorData))
            await engine.process(editorData)
        }
    }

    const handleCreatePreview = (event: any) => {
        alert('The preview will open in a new window.\nYou can copy the link and share it with your collegues.')
        window.open(`/conversations/preview/${conversation.id.split('').reverse().join('')}`, '_blank')
    }

    const handleNameChange = (event: any) => {
        setName(event.target.value)
    }

    const handleTopicChange = (event: any) => {
        setTopic(event.target.value)
    }

    const handleOrganisationChange = (event: any) => {
        setOrganisation(event.target.value)
    }

    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value)
    }

    const handleStatusChange = (event: any) => {
        setStatus(event.target.value)
    }

    const handleUpdateMetadata = () => {
        const { __editor: editor, __engine: engine } = window
        if (editor && engine) {
            const db = firebase.firestore()
            setLoading(true)
            const newConversationData: Partial<Conversation> = {
                name,
                topic,
                organisation,
                language,
                status,
            }
            db.collection('conversations')
                .doc(conversation.id)
                .set(newConversationData, {
                    merge: true,
                })
                .then(() => {
                    enqueueSnackbar('Metadata updated successfully', {
                        variant: 'success',
                    })
                    setLoading(false)
                    handleCloseInfoDialog()
                    setDisplayName(name)
                    setDisplayTopic(topic)
                })
                .catch((_) => {
                    enqueueSnackbar('Something went wrong', {
                        variant: 'error',
                    })
                    setLoading(false)
                })
        }
    }

    const renderStatus = (conversation: Conversation) => {
        if (conversation.status === 'open') {
            return <PublicIcon className={classes.statusIcon} />
        } else if (conversation.status === 'closed') {
            return <ClosedIcon className={classes.statusIcon} />
        } else if (conversation.status === 'locked') {
            return <LockedIcon className={classes.statusIcon} />
        } else {
            return null
        }
    }

    const renderLeftSide = () => {
        return (
            <>
                <Button
                    onClick={handleOpenInfoDialog}
                    variant="outlined"
                    color="default"
                    className={classes.toolbarLeftSideButtonItem}
                >
                    <InfoIcon />
                </Button>
                {displayName} (id: {displayTopic})
            </>
        )
    }

    const renderRightSide = () => {
        if (preview) {
            return <Typography>You are in a preview mode and cannot edit the conversation</Typography>
        } else {
            return (
                <>
                    {!preview && !authenticated && (
                        <Button
                            onClick={handleOpenConversationsDialog}
                            variant="outlined"
                            color="default"
                            className={classes.toolbarRightSideButtonItem}
                        >
                            <QuestionAnswerIcon />
                            &nbsp;&nbsp;All my conversations
                        </Button>
                    )}
                    {authenticated && (
                        <>
                            <Button
                                aria-haspopup="true"
                                onClick={handleManageOpen}
                                variant="outlined"
                                color="default"
                                disabled={loading}
                                className={classes.toobarDropdownButton}
                            >
                                <SettingsIcon />
                                &nbsp;&nbsp;Manage&nbsp;
                                <ArrowDropDownIcon />
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={manageEl}
                                keepMounted
                                open={Boolean(manageEl)}
                                onClose={handleManageClose}
                            >
                                <MenuItem onClick={handleImportConversation}>
                                    <PublishIcon />
                                    &nbsp;&nbsp;Import IS file
                                </MenuItem>
                                <MenuItem onClick={handleExportConversation}>
                                    <GetAppIcon />
                                    &nbsp;&nbsp;Export IS file
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleLoadLabels}>
                                    <TranslateIcon />
                                    &nbsp;&nbsp;Load translations lables
                                </MenuItem>
                                <MenuItem onClick={handleExportLabels}>
                                    <TranslateIcon />
                                    &nbsp;&nbsp;Export translations labels
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleCloneConversation}>
                                    <FileCopyIcon />
                                    &nbsp;&nbsp;Clone this canvas
                                </MenuItem>
                                <MenuItem onClick={handleCreateNewConversation}>
                                    <AddBoxIcon />
                                    &nbsp;&nbsp;Create new canvas
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                    {diagnosisMode ? (
                        <>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <Button
                                aria-haspopup="true"
                                onClick={handleDiagOpen}
                                variant="outlined"
                                color="primary"
                                disabled={loading}
                                className={classes.toobarDropdownButton}
                            >
                                <SpeedIcon />
                                &nbsp;&nbsp;Diagnosis&nbsp;
                                <ArrowDropDownIcon />
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={diagEl}
                                keepMounted
                                open={Boolean(diagEl)}
                                onClose={handleDiagClose}
                            >
                                <MenuItem onClick={handleDiagnoseConversation}>
                                    <SpeedIcon />
                                    &nbsp;&nbsp;Diag
                                </MenuItem>
                                <MenuItem onClick={handleFixConversation}>
                                    <BuildIcon />
                                    &nbsp;&nbsp;Fix
                                </MenuItem>
                                <MenuItem onClick={handleDiagAndSaveConversation}>
                                    <SaveIcon />
                                    &nbsp;&nbsp;Diag &amp; Save
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <Button
                                variant="outlined"
                                color="default"
                                className={classes.toolbarRightSideButtonItem}
                                onClick={handleCreatePreview}
                                disabled={loading}
                            >
                                Create Preview
                            </Button>
                            <Button
                                variant="outlined"
                                color="default"
                                className={classes.toolbarRightSideButtonItem}
                                onClick={handleClearConversation}
                                disabled={loading}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.toolbarRightSideButtonItem}
                                onClick={handleSaveConversation}
                                disabled={loading}
                            >
                                Save
                            </Button>
                        </>
                    )}
                </>
            )
        }
    }

    return (
        <>
            <MaterialToolbar className={classes.toolbar}>
                {renderLeftSide()}
                <div className={classes.spacer}></div>
                {renderRightSide()}
            </MaterialToolbar>

            <Dialog open={infoDialogOpen} onClose={handleCloseInfoDialog} aria-labelledby="info-dialog-title">
                <DialogTitle id="info-dialog-title">Conversation details</DialogTitle>
                <DialogContent>
                    <TextField
                        id="name"
                        name="name"
                        label="Name"
                        variant="outlined"
                        size="small"
                        className={classes.dialogTextItem}
                        value={name}
                        disabled={!authenticated}
                        fullWidth
                        onChange={handleNameChange}
                    />
                    <TextField
                        id="topic"
                        name="topic"
                        label="Topic ID"
                        variant="outlined"
                        size="small"
                        className={classes.dialogTextItem}
                        value={topic}
                        disabled={!authenticated}
                        fullWidth
                        onChange={handleTopicChange}
                    />
                    {authenticated && (
                        <TextField
                            select
                            id="organisation"
                            name="organisation"
                            label="Organisation"
                            variant="outlined"
                            size="small"
                            className={classes.dialogTextItem}
                            value={organisation}
                            disabled={!authenticated}
                            fullWidth
                            onChange={handleOrganisationChange}
                        >
                            {organisations.map((option: Organisation) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    <TextField
                        select
                        id="language"
                        name="language"
                        label="Language"
                        variant="outlined"
                        size="small"
                        className={classes.dialogTextItem}
                        value={language}
                        disabled={!authenticated}
                        fullWidth
                        onChange={handleLanguageChange}
                    >
                        {languages.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {authenticated && (
                        <TextField
                            select
                            id="status"
                            name="status"
                            label="Status"
                            variant="outlined"
                            size="small"
                            className={classes.dialogTextItem}
                            value={status}
                            disabled={!authenticated}
                            fullWidth
                            onChange={handleStatusChange}
                        >
                            <MenuItem value={'open'}>Open</MenuItem>
                            <MenuItem value={'closed'}>Closed</MenuItem>
                            <MenuItem value={'locked'}>Locked</MenuItem>
                        </TextField>
                    )}

                    {!authenticated && (
                        <DialogContentText>
                            If you need to change any of these values, please contact your administrator or Arnold
                            support.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInfoDialog} color="default" variant="outlined">
                        Close
                    </Button>
                    {authenticated && (
                        <Button onClick={handleUpdateMetadata} color="primary" variant="outlined">
                            Update Metadata
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog
                open={conversationsDialogOpen}
                onClose={handleCloseConversationsDialog}
                aria-labelledby="conversations-dialog-title"
            >
                <DialogTitle id="conversations-dialog-title">My conversations</DialogTitle>
                <DialogContent>
                    {otherConversationsLoading ? (
                        <ProgressIndicator text="Loading conversations..." />
                    ) : (
                        <List>
                            <ListItem button onClick={handleCreateNewClientConversation} key="????">
                                <ListItemAvatar>
                                    <Avatar className={classes.newAvatar}>
                                        <AddCircleIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Create new conversation" />
                            </ListItem>
                            <Divider />
                            {otherConversations.length === 0 ? (
                                <DialogContentText>
                                    There are no conversations within this organisation
                                </DialogContentText>
                            ) : (
                                otherConversations.map((con) => (
                                    <ListItem button onClick={() => handleOtherConversationClick(con)} key={con.id}>
                                        <ListItemAvatar>
                                            <Avatar
                                                className={
                                                    con.id === conversation.id ? classes.thisAvatar : classes.avatar
                                                }
                                            >
                                                <QuestionAnswerIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={con.name}
                                            secondary={
                                                con.id === conversation.id ? 'You are editing this conversation' : null
                                            }
                                        />
                                        <ListItemSecondaryAction>{renderStatus(con)}</ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            )}
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConversationsDialog} color="primary" variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={validationsDialogOpen}
                onClose={handleCloseValidationsDialog}
                aria-labelledby="validations-dialog-title"
                maxWidth="lg"
            >
                <DialogTitle id="validations-dialog-title">Validations</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This conversation canvas contains validation errors. Please consider fixing the issues before
                        exporting. Otherwise unexpected behavior might happen when file is imported to other components
                        of Arnold platfrom.
                    </DialogContentText>
                    {validations.map((validation: any, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className={classes.accordionSummary}>
                                    <Typography variant="body1">{validation.validator}</Typography>
                                    <Typography variant="body2" className={classes.meta}>
                                        Expand to see more details
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.accordionDetails}>
                                    <div className={classes.accordionDetailsRow}>
                                        <Typography className={classes.label} variant="body2">
                                            Problem
                                        </Typography>
                                        <Typography variant="body2">{validation.message}</Typography>
                                    </div>
                                    <div className={classes.accordionDetailsRow}>
                                        <Typography className={classes.label} variant="body2">
                                            Recommendation
                                        </Typography>
                                        <Typography variant="caption">{validation.steps}</Typography>
                                    </div>
                                    <div className={classes.accordionDetailsRow}>
                                        <Typography className={classes.label} variant="body2">
                                            Affected Question IDs
                                        </Typography>
                                        <div>
                                            {validation.nodes &&
                                                validation.nodes.length > 0 &&
                                                validation.nodes.map((node: any) => (
                                                    <Chip
                                                        key={node.data.questionId}
                                                        label={node.data.questionId}
                                                        className={classes.chip}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseValidationsDialog} color="primary" variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ToolBar
