export const languages = [
    {
        label: 'English',
        value: 'en',
    },
    {
        label: 'Czech',
        value: 'cs',
    },
    {
        label: 'Slovak',
        value: 'sk',
    },
    {
        label: 'Hungarian',
        value: 'hu',
    },
    {
        label: 'Polish',
        value: 'pl',
    },
    {
        label: 'Serbian',
        value: 'sr',
    },
    {
        label: 'Russian',
        value: 'ru',
    },
]

export const getReadableLanguage = (lang) => {
    const language = languages.find((item) => item.value === lang)
    return language ? language.label : 'Unknown language'
}

export const getReadableOrganisation = (org, organisations) => {
    if (org) {
        const organisation = organisations.find((item) => item.id === org)
        return organisation ? organisation.name : 'Unknown org'
    } else {
        return 'Org not set'
    }
}
