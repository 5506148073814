import React, { useContext, useEffect, useState } from 'react'
import firebase from 'firebase'

import { Organisation } from '../../models/Organisation'
import { Conversation } from '../../models/Conversation'
import { AuthenticationContext } from './AuthenticationProvider'

type ContextProps = {
    organisations: Array<Organisation>
    conversations: Array<Conversation>

    loadingOrganisations: boolean
    loadingConversations: boolean

    getOrganisations: (params?: any) => () => void
    getConversations: (params?: any) => () => void
}

const defaultContext = {
    organisations: [],
    conversations: [],

    loadingOrganisations: false,
    loadingConversations: false,

    getOrganisations: () => () => {},
    getConversations: () => () => {},
}

export const DataContext = React.createContext<ContextProps>(defaultContext)

export const DataProvider = ({ children }: any) => {
    const { authenticated } = useContext(AuthenticationContext)

    const [loadingOrganisations, setLoadingOrganisations] = useState<boolean>(false)
    const [organisations, setOrganisations] = useState<Organisation[]>([])

    const [loadingConversations, setLoadingConversations] = useState<boolean>(false)
    const [conversations, setConversations] = useState<Conversation[]>([])

    const getOrganisations = () => {
        setLoadingOrganisations(true)
        const db = firebase.firestore()
        const colRef = db.collection('organisations').where('archived', '==', false)
        return colRef.onSnapshot((snapshot) => {
            if (snapshot.empty) {
                setLoadingOrganisations(false)
                setOrganisations([])
            } else {
                const results: Organisation[] = []
                snapshot.forEach((doc) =>
                    results.push({
                        ...(doc.data() as Organisation),
                        id: doc.id,
                    })
                )
                setLoadingOrganisations(false)
                setOrganisations(results)
            }
        })
    }

    const getConversations = () => {
        setLoadingConversations(true)
        const db = firebase.firestore()
        const colRef = db.collection('conversations').where('archived', '==', false)
        return colRef.onSnapshot((snapshot) => {
            if (snapshot.empty) {
                setLoadingConversations(false)
                setConversations([])
            } else {
                const results: Array<Conversation> = []
                snapshot.forEach((doc) =>
                    results.push({
                        ...(doc.data() as Conversation),
                        id: doc.id,
                    })
                )
                setLoadingConversations(false)
                setConversations(results)
            }
        })
    }

    useEffect(() => {
        if (authenticated) {
            const unsubscribeConversations = getConversations()
            const unsubscribeOrganisations = getOrganisations()

            return () => {
                if (typeof unsubscribeConversations === 'function') unsubscribeConversations()
                if (typeof unsubscribeOrganisations === 'function') unsubscribeOrganisations()
            }
        }
    }, [authenticated])

    return (
        <DataContext.Provider
            value={{
                organisations,
                loadingOrganisations,
                getOrganisations,
                conversations,
                loadingConversations,
                getConversations,
            }}
        >
            {children}
        </DataContext.Provider>
    )
}
