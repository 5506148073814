import React, { useState } from 'react'
import firebase from 'firebase'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Layout from '../common/Layout'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 600,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    paragraph: {
        marginBottom: theme.spacing(2),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(4),
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(4),
    },
}))

const OrganisationCreatePage: React.FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')

    const handleNameChange = (event: any) => {
        setName(event.target.value)
    }

    const handleCreateConversation = (event: any) => {
        if (name) {
            const db = firebase.firestore()
            setLoading(true)
            const organisation = {
                name,
                archived: false,
            }
            db.collection('organisations')
                .add(organisation)
                .then((_) => {
                    setLoading(false)
                    history.push('/organisations')
                })
                .catch(function (error) {
                    setLoading(false)
                })
        } else {
            alert('Please fill in all fields to create an organisation')
        }
    }

    return (
        <Layout>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <Typography variant="h2" className={classes.header}>
                        Create new organisation
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph}>
                        Please fill all the fields below in order to create a new organisation.
                    </Typography>
                    <Divider />
                    <div className={classes.form}>
                        <TextField
                            id="name"
                            name="name"
                            label="Name"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            value={name}
                            onChange={handleNameChange}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={handleCreateConversation}
                            disabled={loading}
                        >
                            Create organisation
                        </Button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default OrganisationCreatePage
