import Papa from 'papaparse'

/*
INDEX OF INFO IN CSV FILE
0: "QuestionID"
1: "QuestionText"
2: "Type"
3: "Answers"
4: "Language"
*/

export const readLanguageFile = (editor, conversation, updateLanguageCallback) => {
    const input = document.createElement('input')
    input.type = 'file'

    input.onchange = (e) => {
        if (e && e.target) {
            const file = e.target.files[0]

            const reader = new FileReader()
            reader.readAsText(file, 'UTF-8')

            reader.onload = (readerEvent) => {
                if (readerEvent && readerEvent.target && readerEvent.target.result) {
                    const content = readerEvent.target.result
                    updateNodesFromCSV(content, editor, conversation, updateLanguageCallback)
                }
            }
        }
    }

    input.click()
}

const updateNodesFromCSV = (content, editor, conversation, updateLanguageCallback) => {
    const length = conversation.topic ? conversation.topic.length : conversation.topicId.length
    const data = Papa.parse(content, {
        delimiter: '\t',
    })
    const lines = data.data

    // TODO: verify data
    const result = verifyLanguageFile(lines, conversation, editor, updateLanguageCallback)

    if (result) {
        // set nodes to new data
        let iterator = 0
        while (iterator < lines.length) {
            const line = lines[iterator]
            const node = editor.nodes.find((n) => {
                // eslint-disable-next-line
                return n.data.questionId == line[0].trim().substring(length)
            })
            const nodeName = line[2].split(' ')[0].trim()
            if (node) {
                if (node.name === nodeName) {
                    node.data.questionText = line[1]

                    switch (node.name) {
                        case 'SELECT': // fallthrough
                        case 'MULTISELECT': {
                            const newAnswers = line[3].split('|')
                            newAnswers.forEach((answer, index) => {
                                node.data[`opt-${index + 1}`] = answer
                            })
                            break
                        }
                        case 'TELL': // fallthrough
                        case 'FREETEXT': // fallthrough
                        case 'OPTIONAL-TEXT': // fallthrough
                        case 'END': // fallthrough
                        case 'PERSON-SELECT': // fallthrough
                        default:
                            break
                    }
                }
                if (node.name === 'TEXT' && (nodeName === 'FREETEXT' || nodeName === 'OPTIONAL-TEXT')) {
                    node.data.questionText = line[1]
                }
                if (node.name === 'END' && nodeName === 'ENDING') {
                    node.data.questionText = line[1]
                }
                if (node.name === 'PERSON-SELECT' && nodeName === 'PERSON-MULTISELECT') {
                    node.data.questionText = line[1]
                }
            }

            iterator++
        }

        alert(
            'Language file loaded successfully!\n\nTo see the changes take effect, please save the conversation and reload the page.'
        )
    }
}

const verifyLanguageFile = (lines, conversation, editor, updateLanguageCallback) => {
    const length = conversation.topic ? conversation.topic.length : conversation.topicId.length

    if (lines.length !== editor.nodes.length) {
        alert(
            'Warrning!\n\nNumber of nodes in the language file is not the same as number of nodes in conversation.\n\nStopping processing.'
        )
        return false
    }

    if (conversation.language !== lines[0][4]) {
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm(
            'Warrning!\n\nLanguage in langugae file does not correspond with conversationn language.\n\nDo you want to update the language of the conversation?.'
        )

        if (confirmation) {
            updateLanguageCallback(lines[0][4])
            return true
        } else {
            return false
        }
    }

    const badLines = []
    lines.forEach((line) => {
        const node = editor.nodes.find((n) => {
            // eslint-disable-next-line
            return n.data.questionId == line[0].trim().substring(length)
        })
        const nodeName = line[2].split(' ')[0].trim()
        if (node) {
            // first check types
            if (node.name === 'END' && nodeName !== 'ENDING') {
                badLines.push({ reason: 'node types are incorrect', line })
            }
            if (node.name === 'TELL' && nodeName !== 'TELL') {
                badLines.push({ reason: 'node types are incorrect', line })
            }
            if (nodeName === 'FREETEXT' || nodeName === 'OPTIONAL-TEXT') {
                if (node.name !== 'TEXT') {
                    badLines.push({ reason: 'node types are incorrect', line })
                }
            }
            if (node.name === 'PERSON-SELECT' && nodeName !== 'PERSON-MULTISELECT') {
                badLines.push({ reason: 'node types are incorrect', line })
            }
            if (node.name === 'SELECT' && nodeName !== 'SELECT') {
                badLines.push({ reason: 'node types are incorrect', line })
            }
            if (node.name === 'MULTISELECT' && nodeName !== 'MULTISELECT') {
                badLines.push({ reason: 'node types are incorrect', line })
            }

            // check number of answers in answer types
            if (node.name === 'SELECT' || node.name === 'MULTISELECT') {
                const len1 = Object.keys(node.data).filter((key) => key.startsWith('opt-')).length
                const len2 = line[3].split('|').length
                if (len1 !== len2) {
                    badLines.push({
                        reason: 'number on answers are incorrect',
                        line,
                    })
                }
            }
        } else {
            badLines.push({ reason: 'node not found in connversation', line })
        }
    })
    if (badLines.length > 0) {
        console.log('')
        console.log('===== LANGUAGE FILE VERIFY LOG =====')
        console.log(badLines)
        alert(
            'Warrning!\n\nSome lines in the language file are not okay. See additional logging in console.\n\nStopping processing.'
        )
        return false
    }

    return true
}

const createBaseCsvLineFromNode = (node, conversation) => {
    let nodeName = node.name
    if (nodeName === 'TEXT') {
        nodeName = node.data.checkbox ? 'FREETEXT' : 'OPTIONAL-TEXT'
    } else if (nodeName === 'PERSON-SELECT') {
        nodeName = 'PERSON-MULTISELECT'
    }

    return [
        (conversation.topic || conversation.topicId) + (node.data.questionId || node.id),
        node.data.questionText || '',
        nodeName,
        '',
        conversation.language,
    ]
}

export const writeLanguageFile = (editor, conversation) => {
    const csvData = {
        data: [],
    }

    const editorData = editor.toJSON()

    if (editorData) {
        Object.keys(editorData.nodes).forEach((key) => {
            const node = editorData.nodes[key]
            const csvLine = createBaseCsvLineFromNode(node, conversation)
            if (node.name === 'SELECT' || node.name === 'MULTISELECT') {
                const options = Object.keys(node.data).filter((key) => key.startsWith('opt-'))
                csvLine[3] = options.map((key) => node.data[key]).join('|')
            }
            if (node.name === 'END') {
                csvLine[2] = 'ENDING'
            }

            csvData.data.push(csvLine)
        })

        var csv = Papa.unparse(csvData, {
            quotes: false,
            delimiter: '\t',
        })
        var csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        var csvURL = null
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(
                csvBlob,
                `con_${conversation.topic || conversation.topicId}_${conversation.language.toLowerCase()}_${
                    conversation.name
                }.txt`
            )
        } else {
            csvURL = window.URL.createObjectURL(csvBlob)
        }
        var tempLink = document.createElement('a')
        tempLink.href = csvURL
        tempLink.setAttribute(
            'download',
            `con_${conversation.topic || conversation.topicId}_${conversation.language.toLowerCase()}_${
                conversation.name
            }.txt`
        )
        tempLink.click()
    }
}
