import React, { useEffect, useState } from 'react'
import firebase from 'firebase'

type ContextProps = {
    user: firebase.User | null
    authenticated: boolean
    setUser: (user: any) => void
    loadingAuthState: boolean
}

const defaultContext = {
    user: null,
    authenticated: false,
    setUser: () => {},
    loadingAuthState: false,
}

export const AuthenticationContext = React.createContext<ContextProps>(
    defaultContext
)

export const AuthenticationProvider = ({ children }: any) => {
    const [user, setUser] = useState(null as firebase.User | null)
    const [loadingAuthState, setLoadingAuthState] = useState(true)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user: any) => {
            setUser(user)
            setLoadingAuthState(false)
        })
    }, [])

    return (
        <AuthenticationContext.Provider
            value={{
                user,
                authenticated: user !== null,
                setUser,
                loadingAuthState,
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    )
}
