import { createMuiTheme } from '@material-ui/core/styles'

export const muiTheme = createMuiTheme({
    palette: {
        text: {
            primary: '#3F3F3F',
            secondary: '#A1A1A1',
            disabled: '#BDBDBD',
            hint: '#A1A1A1',
        },
        primary: {
            light: '#03C3FF',
            main: '#04B7EF',
            dark: '#04A5D8',
            contrastText: '#FFFFFF',
        },
        // secondary: {
        //   light: "#D1E9F7",
        //   main: "#C5E4F5",
        //   dark: "#AEC8D7",
        // },
        // as error for now
        secondary: {
            light: '#E27F95',
            main: '#DB5F7B',
            dark: '#AF4C62',
        },
        info: {
            light: '#D1E9F7',
            main: '#C5E4F5',
            dark: '#AEC8D7',
        },
        warning: {
            light: '#F3D893',
            main: '#F1D07C',
            dark: '#EBC462',
        },
        error: {
            light: '#E27F95',
            main: '#DB5F7B',
            dark: '#AF4C62',
        },
        success: {
            light: '#C5DA8A',
            main: '#B7D16D',
            dark: '#92A757',
        },
    },
    typography: {
        h1: {
            fontFamily: 'Quicksand',
            fontSize: 64,
            fontWeight: 700,
            lineHeight: 1.2,
        },
        h2: {
            fontFamily: 'Quicksand',
            fontSize: 48,
            fontWeight: 700,
            lineHeight: 1.2,
        },
        h3: {
            fontFamily: 'Roboto',
            fontSize: 34,
            fontWeight: 300,
            lineHeight: 1.2,
        },
        h4: {
            fontFamily: 'Roboto',
            fontSize: 22,
            fontWeight: 400,
            lineHeight: 1.2,
        },
        h5: {
            fontFamily: 'Roboto',
            fontSize: 17,
            fontWeight: 500,
            lineHeight: 1.45,
        },
    },
})

export const snackTheme = {
    success: { backgroundColor: '#B7D16D !important' },
    error: { backgroundColor: '#DB5F7B !important' },
    warning: { backgroundColor: '#F1D07C !important' },
    info: { backgroundColor: '#C5E4F5 !important' },
}
