import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PublicIcon from '@material-ui/icons/Public'
import ClosedIcon from '@material-ui/icons/Cancel'
import LockedIcon from '@material-ui/icons/Lock'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { debounce } from 'lodash'

import Layout from '../common/Layout'
import { languages } from '../../utils/options'
import { getReadableLanguage, getReadableOrganisation } from '../../utils/options'
import ProgressIndicator from '../common/ProgressIndicator'
import { DataContext } from '../context/DataProvider'
import { Conversation } from '../../models/Conversation'
import { useLocalStorage } from 'hooks/useLocalStorage'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    filterTextField: {
        width: '100%',
    },
    table: {
        width: '100%',
    },
    statusIcon: {
        width: '16px',
        marginRight: '8px',
        position: 'relative',
        top: '6px',
        color: 'gray',
    },
}))

const ConversationListPage: React.FC = () => {
    const classes = useStyles()
    const { organisations, loadingOrganisations, conversations, loadingConversations } = useContext(DataContext)

    const [topic, setTopic] = useLocalStorage('conversationTopicFilter', '')
    const [name, setName] = useLocalStorage('conversationNameFilter', '')
    const [organisation, setOrganisation] = useLocalStorage('conversationOrganisationFilter', '')
    const [language, setLanguage] = useLocalStorage('conversationLanguageFilter', '')
    const [conversationsToRender, setConversationsToRender] = useState<Array<Conversation>>([])

    const handleClearFilter = () => {
        setTopic('')
        setName('')
        setOrganisation('')
        setLanguage('')
    }

    const handleTopicChange = (event: any) => {
        setTopic(event.target.value)
    }

    const handleNameChange = (event: any) => {
        setName(event.target.value)
    }

    // TODO: still need proper organisation handling
    const handleOrganisationChange = (event: any) => {
        setOrganisation(event.target.value)
    }

    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleFilter = useCallback(
        debounce((conversations, topic, name, organisation, language) => {
            let filtered = conversations

            if (topic) {
                const topicFiltered = filtered.filter((c: Conversation) => c.topic?.indexOf(topic) > -1)
                filtered = topicFiltered
            }

            if (name) {
                const nameFiltered = filtered.filter(
                    (c: Conversation) => c.name.toLowerCase().indexOf(name.toLowerCase()) > -1
                )
                filtered = nameFiltered
            }

            if (organisation) {
                if (organisation === '__unassigned__') {
                    const organisationFiltered = filtered.filter(
                        (c: Conversation) => typeof c.organisation === 'undefined' || c.organisation === ''
                    )
                    filtered = organisationFiltered
                } else {
                    const organisationFiltered = filtered.filter((c: Conversation) => c.organisation === organisation)
                    filtered = organisationFiltered
                }
            }

            if (language) {
                const languageFiltered = filtered.filter((c: Conversation) => c.language === language)
                filtered = languageFiltered
            }

            setConversationsToRender(filtered)
        }, 300),
        []
    )

    const renderStatus = (conversation: Conversation) => {
        if (conversation.status === 'open') {
            return <PublicIcon className={classes.statusIcon} />
        } else if (conversation.status === 'closed') {
            return <ClosedIcon className={classes.statusIcon} />
        } else if (conversation.status === 'locked') {
            return <LockedIcon className={classes.statusIcon} />
        } else {
            return null
        }
    }

    useEffect(() => {
        if (organisations && conversations && conversations.length > 0) {
            if (topic || name || organisation || language) {
                handleFilter(conversations, topic, name, organisation, language)
            } else {
                setConversationsToRender(conversations)
            }
        }
    }, [organisations, conversations, topic, name, organisation, language, handleFilter])

    return (
        <Layout>
            <div className={classes.container}>
                <div className={classes.wrapper}>
                    <div className={classes.header}>
                        <Typography variant="h2">Conversation list</Typography>
                        <Button component={Link} to={'/conversations/create'} color="primary" variant="outlined">
                            <AddCircleIcon />
                            &nbsp;&nbsp;Create new conversation
                        </Button>
                    </div>
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Topic ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Organisation</TableCell>
                                    <TableCell>Language</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            id="topic"
                                            name="topic"
                                            label="Topic"
                                            variant="outlined"
                                            size="small"
                                            className={classes.filterTextField}
                                            value={topic}
                                            onChange={handleTopicChange}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Name"
                                            variant="outlined"
                                            size="small"
                                            className={classes.filterTextField}
                                            value={name}
                                            onChange={handleNameChange}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            select
                                            id="organisation"
                                            name="organisation"
                                            label="Organisation"
                                            variant="outlined"
                                            size="small"
                                            className={classes.filterTextField}
                                            value={organisation}
                                            onChange={handleOrganisationChange}
                                        >
                                            <MenuItem key={'__unassigned__'} value={'__unassigned__'}>
                                                Unassigned
                                            </MenuItem>
                                            {organisations.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            select
                                            id="language"
                                            name="language"
                                            label="Language"
                                            variant="outlined"
                                            size="small"
                                            className={classes.filterTextField}
                                            value={language}
                                            onChange={handleLanguageChange}
                                        >
                                            <MenuItem key={''} value={''}>
                                                No language
                                            </MenuItem>
                                            {languages.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button variant="outlined" color="default" onClick={handleClearFilter}>
                                            Clear filter
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                {loadingOrganisations || loadingConversations ? (
                                    <TableRow>
                                        <TableCell component="th" scope="row" colSpan={5}>
                                            <ProgressIndicator text="Loading conversations..." />
                                        </TableCell>
                                    </TableRow>
                                ) : conversationsToRender.length > 0 ? (
                                    conversationsToRender.map((conversation: Conversation) => (
                                        <TableRow key={conversation.id} hover>
                                            <TableCell component="th" scope="row">
                                                {conversation.topic || conversation.topicId!}
                                            </TableCell>
                                            <TableCell>
                                                {renderStatus(conversation)} {conversation.name}
                                            </TableCell>
                                            <TableCell>
                                                {getReadableOrganisation(conversation.organisation, organisations)}
                                            </TableCell>
                                            <TableCell>{getReadableLanguage(conversation.language)}</TableCell>
                                            <TableCell align="right">
                                                <IconButton component={Link} to={`/conversations/${conversation.id}`}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    component={Link}
                                                    to={`/conversations/edit/${conversation.id}`}
                                                >
                                                    <PlayCircleFilledIcon />
                                                </IconButton>
                                                <IconButton
                                                    component={Link}
                                                    to={`/conversations/preview/${conversation.id
                                                        .split('')
                                                        .reverse()
                                                        .join('')}`}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell component="th" scope="row" colSpan={5} align="center">
                                            No conversations have been found, try clearing your filter or refrshing data
                                            via admin menu.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Layout>
    )
}

export default ConversationListPage
